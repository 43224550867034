import {
     paymentDetails as paymentDetailsAPI,
     getCreditCard as getCreditCardAPI,
     getCardDetails as getCardDetailsAPI
} from "api/payment.js";
import { bankStatus as bankStatusAPI } from "api/transaction.js";
import { defineStore } from "pinia";
import _ from "underscore";
import { authStore } from "store/auth.js";

export const cardStore = defineStore("cards", {
     state: () => {
          return {
               cards: null,
               cardsRedeem: null,
               bankStatus: null,
               condition: [
                    {
                         brand: "visa",
                         type: "debit"
                    },
                    {
                         brand: "visa",
                         type: "credit"
                    }
                    // {
                    //      brand: "mastercard",
                    //      type: "debit"
                    // }
               ]
          };
     },
     actions: {
          getCards(isForce = false) {
               if (this.cards != null && !isForce) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    paymentDetailsAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   if (response?.list?.length) {
                                        const map = _.map(
                                             response.list,
                                             function (params) {
                                                  return params.paypage
                                                       .firstSix;
                                             }
                                        );

                                        this.getCardDetails(
                                             map,
                                             response?.list,
                                             "top-up"
                                        );
                                   } else {
                                        state.cards = [];
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              if (
                                   error.response.status == 503 &&
                                   ["time", "days"].includes(
                                        error.response.data?.type
                                   )
                              ) {
                                   const useAuthStore = authStore();

                                   useAuthStore.setSelfExclusionTimer(
                                        error.response.data
                                   );
                                   return;
                              }

                              // errorToast('Error to load data!', "top-right");
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          },
          getCardsRedeem(isForce = false) {
               if (this.cardsRedeem != null && !isForce) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    getCreditCardAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   if (response?.list?.length) {
                                        const map = _.map(
                                             response.list,
                                             function (params) {
                                                  return params.paypage
                                                       .firstSix;
                                             }
                                        );

                                        this.getCardDetails(
                                             map,
                                             response?.list,
                                             "redeem"
                                        );
                                   } else {
                                        state.cardsRedeem = [];
                                   }
                              });
                         })
                         .catch((error) => {
                              // errorToast('Error to load data!', "top-right");
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          },
          getBankStatus() {
               return new Promise((resolve, reject) => {
                    bankStatusAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.bankStatus = response;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              // errorToast('Error to load data!', "top-right");
                              this.$patch((state) => {
                                   state.bankStatus = false;
                              });
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          },
          getCardDetails(params, list, type) {
               return new Promise((resolve, reject) => {
                    getCardDetailsAPI({ firstsix: params })
                         .then((response) => {
                              this.$patch((state) => {
                                   // visa debit = OK
                                   // mastercard debit = OK
                                   // visa credit = OK
                                   // mastercard credit = NOT OK

                                   // condition for acceptable card

                                   const acceptableList = _.filter(
                                        response,
                                        function (params) {
                                             for (
                                                  let index = 0;
                                                  index <
                                                  state.condition.length;
                                                  index++
                                             ) {
                                                  if (
                                                       params[
                                                            "brand"
                                                       ].toLowerCase() ==
                                                            state.condition[
                                                                 index
                                                            ]["brand"] &&
                                                       params[
                                                            "type"
                                                       ].toLowerCase() ==
                                                            state.condition[
                                                                 index
                                                            ]["type"]
                                                  ) {
                                                       return params.bin;
                                                  }
                                             }
                                        }
                                   );

                                   if (acceptableList.length != 0) {
                                        const pluck = _.pluck(
                                             acceptableList,
                                             "bin"
                                        );

                                        const filterList = _.map(
                                             list,
                                             function (params) {
                                                  params.paypage["isAccepted"] =
                                                       pluck.includes(
                                                            params.paypage
                                                                 .firstSix
                                                       );
                                                  return params;
                                             }
                                        );

                                        if (type == "redeem") {
                                             state.cardsRedeem = filterList;
                                        } else {
                                             state.cards = filterList;
                                        }
                                   } else {
                                        const filterList = _.map(
                                             list,
                                             function (params) {
                                                  params.paypage["isAccepted"] =
                                                       false;
                                                  return params;
                                             }
                                        );

                                        if (type == "redeem") {
                                             state.cardsRedeem = filterList;
                                        } else {
                                             state.cards = filterList;
                                        }
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              // errorToast('Error to load data!', "top-right");
                              this.$patch((state) => {
                                   state.bankStatus = false;
                              });
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          }
     }
});
