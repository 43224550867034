<template>
     <div class="md:hidden fixed left-5 bottom-5 z-[52]">
          <img
               @click="goto"
               :src="baseUrl.img + '/assets/img/get_coins_icon.png'"
               class="w-14 h-auto cursor-pointer scale-1 hover:scale-105 transition-transform ease-in duration-300"
          />
     </div>
</template>
<script>
import { baseUrl } from "config/services.js";
import { useRouter } from "vue-router";
import { useKYC } from "composables/use-kyc.js";

export default {
     setup() {
          const router = useRouter();
          const { validateEveryAction } = useKYC();

          const goto = () => {
               if (validateEveryAction("gold-coin-shop") == true) {
                    return;
               }
               router.push("/gold-coin-shop");
          };

          return {
               baseUrl,
               router,
               goto
          };
     }
};
</script>
