import Cookies from "js-cookie";

export const useUtm = () => {
     const setUtmParams = () => {
          const urlParams = new URLSearchParams(window.location.search);
          const utmSource = urlParams.get("utm_source");
          if (utmSource) {
               const utmParams = {};
               let utmExpiration = 7; // default expiration in days
               for (const [key, value] of urlParams.entries()) {
                    if (key.startsWith("utm_")) {
                         utmParams[key] = value;
                         if (key === "utm_expiration") {
                              utmExpiration = parseInt(value, 10) || 7; // use provided expiration or default to 3 days
                         }
                    }
               }
               Cookies.set("utm_params", JSON.stringify(utmParams), {
                    expires: utmExpiration
               });
          }
     };
     return {
          setUtmParams
     };
};
