import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

export const useFingerprint = () => {
     const initFingerprint = async () => {
          const fpPromise = FingerprintJS.load({
               apiKey: "nocjN6PfuzBSPBlBV1ki"
          });

          return fpPromise
               .then((fp) => {
                    return fp.get();
               })
               .then((result) => {
                    return result;
               });
     };

     return {
          initFingerprint
     };
};
