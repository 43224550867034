export const vip_form = [
     {
          question: "Source of wealth",
          input: "dropdown",
          key: "source_of_income",
          options: [
               "Salary or wages from employment",
               "Proceeds from the sale of assets (e.g: real estate, investments)",
               "Family inheritance of gifts",
               "Lottery or gambling winnings",
               "Business income",
               "Other"
          ]
     },
     {
          question: "Current employment field",
          input: "dropdown",
          key: "employment_field",
          options: [
               "Education",
               "Healthcare",
               "Human Resources",
               "Accounting",
               "Hospitality",
               "Finance",
               "Sales",
               "Medical",
               "Management",
               "Engineering",
               "Government",
               "IT",
               "law",
               "Marketing",
               "Construction",
               "Arts"
          ]
     },
     {
          question: "Length of employment ",
          input: "dropdown",
          key: "length_of_employment",
          options: [
               "1 year",
               "2 year",
               "3 year",
               "4 year",
               "5 year",
               "6 year",
               "7+ year"
          ]
     },
     {
          question: "What is your income or salary range?",
          input: "dropdown",
          key: "salary_range",
          options: [
               "$0-$19,999",
               "$20,000-$49,999",
               "$50,000-$74,999",
               "$75,000-$99,999",
               "$100,000-$150,000",
               "$150,000+"
          ]
     },
     {
          question: "If you own your own business, how long have you owned it",
          input: "dropdown",
          key: "years_of_business",
          options: [
               "Less than 1 year",
               "1-3 years",
               "3-5 years",
               "5-10 years",
               "10+ years"
          ]
     },
     {
          question: "What is net wealth (after debt)?",
          input: "dropdown",
          key: "net_wealth",
          options: [
               "$0-$49,999",
               "$50,000-$100,000",
               "$100,000-$200,000",
               "$200,000-500,000",
               "$500,000+"
          ]
     },
     {
          question:
               "If you have investments, what type of investments do you own? Tick all relevant boxes?",
          input: "checkbox",
          key: "investments",
          options: [
               "Real estate",
               "Stocks",
               "Cryptocurrencies",
               "Bonds",
               "Derivatives and options",
               "Venture capital investments"
          ]
     },
     {
          question:
               "If you have investments, how did you acquire these investments?",
          input: "checkbox",
          key: "acquire_investments",
          options: [
               "Funded by salary",
               "Funded by business income",
               "Funded by inheritance",
               "Funded by savings",
               "Funded by dividends",
               "Funded by rent",
               "Funded by investment gains",
               "I do not own investments"
          ]
     },
     {
          question:
               "Have you received any significant inheritance in the past?",
          input: "dropdown",
          key: "received_inheritance",
          options: ["Yes", "No"]
     },
     {
          question:
               "Have you received any significant inheritance in the past?",
          input: "dropdown",
          key: "received_inheritance_from",
          options: [
               "Grandparents",
               "Parents",
               "Trusts",
               "Siblings",
               "Children",
               "Other relatives",
               "Friends",
               "Charity",
               "Non-family people",
               "I did not receive any significant inheritance"
          ]
     },
     {
          question:
               "If you received significant inheritance, when did you receive it?",
          input: "dropdown",
          key: "received_inheritance_when",
          options: [
               "Less than 1 year ago",
               "1-2 years ago",
               "3-5 years ago",
               "5-10 years ago",
               "10+ years ago",
               "I did not receive any significant inheritance"
          ]
     },
     {
          question: "Do you own any residential or commercial properties?",
          input: "dropdown",
          key: "own_property",
          options: ["Yes ", "No"]
     },
     {
          question:
               "If you own residential or commercial properties, how did you acquire these properties?",
          input: "checkbox",
          key: "acquire_property",
          options: [
               "Funded by salary",
               "Funded by business income",
               "Funded by inheritance",
               "Funded by savings",
               "Funded by dividends",
               "Funded by rent",
               "Funded by investment gains",
               "I do not own residential or commercial properties"
          ]
     },
     {
          question: "Do you receive any other significant source of income?",
          input: "checkbox",
          key: "any_source_of_income",
          options: [
               "Dividends",
               "Rental income",
               "Capital gains",
               "Inheritance",
               "Business income"
          ]
     }
];
