<template>
     <footer
          class="relative bg-moozi-7 text-white py-4 lg:py-14 tracking-wider font-extralight font-inter"
     >
          <div
               class="text-center w-full 3xl:max-w-screen-3xl mx-auto transition-all duration-300 ease-in-out"
               :class="
                    menu
                         ? 'px-3 sm:px-5 md:px-10 lg:px-12 xl:px-14 2xl:px-18'
                         : 'px-8 xs:px-12 sm:px-18 md:px-24 lg:px-28 xl:px-32'
               "
          >
               <div
                    class="flex flex-col-reverse lg:flex-row gap-2 lg:gap-8 items-center xl:items-start font-roboto tracking-widest font-bold text-base xl:text-lg"
               >
                    <div
                         class="hidden lg:flex flex-initial flex-row items-center"
                    >
                         <img
                              :src="
                                   baseUrl.img +
                                   '/assets/img/footer/footer-animal.avif'
                              "
                              alt=""
                              class="w-4/12"
                         />
                         <div
                              class="flex lg:w-4/12 xl:w-3/12 flex-col justify-center"
                         >
                              <div class="flex flex-col">
                                   <img
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/footer/footer-logo-line-2.svg'
                                        "
                                        alt=""
                                        class="-mb-1"
                                   />
                                   <div class="flex w-full justify-center">
                                        <img
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/footer/footer-logo.svg'
                                             "
                                             alt=""
                                             class="w-6/12"
                                        />
                                   </div>
                                   <img
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/footer/footer-logo-line-1.svg'
                                        "
                                        alt=""
                                        class="mt-6"
                                   />
                              </div>
                         </div>
                    </div>
                    <div
                         class="w-full lg:w-max flex flex-col text-center items-center justify-end gap-5 lg:text-right space-y-4 mt-5 sm:mt-10 lg:mt-0"
                    >
                         <div
                              class="w-full lg:w-max flex flex-col sm:flex-row justify-evenly lg:justify-start gap-5 sm:gap-20 md:gap-16 lg:gap-20 xl:gap-44 !mt-0"
                         >
                              <div
                                   class="flex flex-col items-center sm:items-start justify-center sm:justify-start w-full sm:w-32 lg:w-40 xl:w-44"
                              >
                                   <p
                                        class="tracking-wider text-sm xs:text-base sm:text-lg lg:text-xl xl:text-2xl text-center sm:text-start transform ease-in-out transition-all duration-200 mb-1 xs:mb-2"
                                   >
                                        Social Casino
                                   </p>
                                   <a
                                        v-if="isUser"
                                        @click="goto('redeem')"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base text-center sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        Redeem
                                   </a>
                                   <a
                                        @click="goto('promotion')"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        Promotions
                                   </a>
                                   <a
                                        v-if="isUser"
                                        @click="goto('gold-coin-shop')"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        Get coins
                                   </a>
                              </div>
                              <div
                                   class="flex flex-col items-center sm:items-start justify-center sm:justify-start w-full sm:w-52 xl:w-60"
                              >
                                   <p
                                        class="tracking-wider text-sm xs:text-base sm:text-lg lg:text-xl xl:text-2xl text-center sm:text-start transform ease-in-out transition-all duration-200 mb-1 xs:mb-2"
                                   >
                                        Support
                                   </p>
                                   <!-- <a
                                        @click="goto('submit-a-request')"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        Contact Us
                                   </a> -->
                                   <a
                                        @click="goto('support')"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        FAQ
                                   </a>
                                   <a
                                        v-if="isUser"
                                        @click="goto('code')"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        Unique Request Code
                                   </a>
                              </div>
                         </div>
                         <div
                              class="w-full lg:w-max flex flex-col sm:flex-row justify-evenly lg:justify-start gap-5 sm:gap-20 md:gap-16 lg:gap-20 xl:gap-44 !mt-0"
                         >
                              <div
                                   class="flex flex-col items-center sm:items-start justify-center sm:justify-start w-full sm:w-32 lg:w-40 xl:w-44"
                              >
                                   <p
                                        class="tracking-wider text-sm xs:text-base sm:text-lg lg:text-xl xl:text-2xl text-center sm:text-start transform ease-in-out transition-all duration-200 mb-1 xs:mb-2"
                                   >
                                        Community
                                   </p>
                                   <a
                                        @click="redirectTo(links['facebook'])"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        Facebook
                                   </a>
                                   <a
                                        @click="redirectTo(links['instagram'])"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        Instagram
                                   </a>
                                   <a
                                        @click="redirectTo(links['x'])"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        X
                                   </a>
                              </div>
                              <div
                                   class="flex flex-col items-center sm:items-start justify-center w-full sm:w-52 xl:w-60"
                              >
                                   <p
                                        class="tracking-wider text-sm xs:text-base sm:text-lg lg:text-xl xl:text-2xl text-center sm:text-start transform ease-in-out transition-all duration-200 mb-1 xs:mb-2"
                                   >
                                        About Us
                                   </p>
                                   <a
                                        @click="goto('terms-of-use')"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        Terms of Service
                                   </a>
                                   <a
                                        @click="goto('privacy')"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        Privacy of Policy
                                   </a>
                                   <a
                                        @click="goto('sc-coins')"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        SC Promotional Rules
                                   </a>
                                   <a
                                        @click="goto('player-safety')"
                                        class="cursor-pointer text-xs xs:text-sm md:text-base sm:text-start tracking-wider transform ease-in-out transition-all duration-200 text-moozi-6 !font-normal w-max sm:mb-1"
                                   >
                                        Responsible Play
                                   </a>
                              </div>
                         </div>
                    </div>
               </div>
               <div
                    class="flex lg:hidden w-full items-center justify-center mt-10 md:mt-14"
               >
                    <img
                         :src="
                              baseUrl.img + '/assets/img/footer/footer-logo.svg'
                         "
                         class="w-20 xs:w-22 md:w-2/12"
                         alt=""
                    />
               </div>
               <div class="mt-10 max-w-2xl px-0 md:px-10 text-center mx-auto">
                    <p
                         class="font-helveticaLight tracking-wider font-bold text-xs md:text-sm text-moozi-6"
                    >
                         This site is operated by Moshy Gaming LLC of 251 Little
                         Falls Drive, Wilmington DE 19808, you can contact us
                         for support at support@moozi.com
                    </p>
               </div>
          </div>
     </footer>
</template>
<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { authStore } from "store/auth.js";
import { baseUrl } from "config/services.js";
import { useScroll } from "composables/use-scroll.js";

export default {
     props: ["menu"],
     setup() {
          const router = useRouter();

          const { scrollTop } = useScroll();

          const links = {
               facebook:
                    "https://www.facebook.com/profile.php?id=61559086152299&mibextid=LQQJ4d",
               x: "https://x.com/MooziCasino",
               instagram: "https://www.instagram.com/moozi_casino/"
          };

          const useAuthStore = authStore();

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const redirectTo = (path) => {
               window.open(path);
          };

          const goto = (path) => {
               router.push({ path: "/" + path, force: true });
               scrollTop();
          };

          return {
               router,
               goto,
               redirectTo,
               links,
               isUser,
               baseUrl
          };
     }
};
</script>
