<template>
     <InertiaHead title="Games"></InertiaHead>
     <loading-overlay :isLoading="loading" />
     <div
          class="w-full bg-black relative"
          :class="{
               'h-[calc(100vh-64px)] mt-[64px]': deviceType() !== 'mobile', //desktop view
               'h-[100dvh]': deviceType() === 'mobile' // mobile view autofullscreen
          }"
     >
          <iframe
               id="slot-iframe"
               class="block w-full h-full transition-all duration-300 ease-in-out"
               v-if="url"
               :key="url"
               :src="url"
               frameborder="0"
               allowfullscreen
               allow="geolocation; bluetooth; accelerometer; gyroscope; cross-origin-isolated"
          ></iframe>
     </div>
     <Expired />
</template>

<script>
import {
     ref,
     computed,
     onMounted,
     onUnmounted,
     onBeforeMount,
     onBeforeUnmount,
     watch
} from "vue";
import { useRoute } from "vue-router";
import { useIOSocketInit } from "composables/use-socket-init.js";
import { authStore } from "@/store/auth";
import { Head as InertiaHead } from "@inertiajs/vue3";
// import Cookies from 'js-cookie';
import Expired from "./component/expired.vue";
import { useScroll } from "composables/use-scroll.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import device from "middleware/device.js";
import { utilitiesStore } from "store/utilities.js";
import { gamesStore } from "store/games";

export default {
     components: {
          Expired,
          InertiaHead,
          LoadingOverlay
     },
     setup() {
          const route = useRoute();
          const useAuthStore = authStore();
          const { emitGameCloseIO, emitGameOpenIO, emitHeartbeatIO } =
               useIOSocketInit();
          const { scrollTop } = useScroll();
          const { deviceType } = device();
          const chatButton = document.querySelector("#chat-button");
          const chatWidget = document.querySelector("#chat-widget-container");
          const eyeCatcher = document.querySelector("#livechat-eye-catcher");

          const useUtilitiesStore = utilitiesStore();
          const useGamesStore = gamesStore();

          const loading = ref(false);

          const isUser = computed(() => {
               return useAuthStore.user || null;
          });

          const preloading = computed(() => {
               return useUtilitiesStore.loading;
          });

          const isMobile = ref(false);
          // const url = ref(null);

          const url = computed(() => {
               const urlParam = useAuthStore.active_game_url;
               if (urlParam != "") {
                    if (Array.isArray(urlParam)) {
                         return decodeURIComponent(urlParam[0]);
                    } else {
                         return decodeURIComponent(urlParam);
                    }
               }
          });

          const active_coin_type = computed(() => {
               return useAuthStore.active_coin_type || route.params.type;
          });

          const myEventHandler = () => {
               if (deviceType() == "mobile") {
                    isMobile.value = true;
               } else {
                    isMobile.value = false;
               }
          };

          const handleBeforeUnload = () => {
               emitGameCloseIO();
          };

          let heartbeatInterval;

          onBeforeMount(async () => {
               emitGameOpenIO();
          });

          onMounted(async () => {
               scrollTop();
               myEventHandler();
               await useGamesStore.getProvider();

               if (!preloading.value) {
                    loading.value = true;
               }

               if (deviceType() != "mobile") {
                    if (chatButton) {
                         chatButton.classList.add("hidden");
                    }
                    if (chatWidget) {
                         chatWidget.classList.add("hidden");
                    }
                    if (eyeCatcher) {
                         eyeCatcher.classList.add("hidden");
                    }
               }

               setTimeout(() => {
                    loading.value = false;
               }, 1500);

               heartbeatInterval = setInterval(() => {
                    emitHeartbeatIO();
               }, 60000); // 60000 ms = 1 minute

               // Add beforeunload event listener
               window.addEventListener("beforeunload", handleBeforeUnload);
          });

          onUnmounted(() => {
               clearInterval(heartbeatInterval);
               if (deviceType() != "mobile") {
                    if (chatButton) {
                         chatButton.classList.remove("hidden");
                    }
                    if (chatWidget) {
                         chatWidget.classList.remove("hidden");
                    }
                    if (eyeCatcher) {
                         eyeCatcher.classList.remove("hidden");
                    }
               }
               // Remove beforeunload event listener
               window.removeEventListener("beforeunload", handleBeforeUnload);
          });

          onBeforeUnmount(() => {
               emitGameCloseIO();
               // socket.off("game:get");
          });

          watch(active_coin_type, (newVal) => {
               if (newVal) {
                    loading.value = true;
                    setTimeout(() => {
                         loading.value = false;
                    }, 1500);
               }
          });

          watch(isUser, (newVal) => {
               if (newVal) {
                    emitGameCloseIO();
                    emitGameOpenIO();
               }
          });

          return {
               url,
               isMobile,
               loading,
               deviceType
          };
     }
};
</script>
