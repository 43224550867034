<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenIdVerification"
               id="authentication-modal"
               tabindex="-1"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 flex justify-center items-center"
          >
               <div
                    class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-lg max-h-full tracking-wider m-auto"
               >
                    <!-- Modal content -->
                    <div class="relative bg-white rounded-lg shadow">
                         <button
                              v-if="!isComplete"
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <!-- Modal header -->
                         <div
                              class="px-5 flex items-center justify-center mb-1 pt-4"
                         >
                              <h3
                                   class="text-3xl font-semibold text-gray-900 uppercase"
                              >
                                   ID Verification
                              </h3>
                         </div>
                         <div class="px-5 pb-4 font-inter">
                              <div
                                   id="websdk-container"
                                   style="
                                        min-height: 20rem;
                                        background-color: #fff;
                                   "
                              ></div>
                              <div
                                   v-if="isComplete"
                                   class="flex items-center justify-center mb-5"
                              >
                                   <button
                                        type="button"
                                        @click="back2Home()"
                                        class="rounded-full font-roboto font-bold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg cursor-pointer"
                                   >
                                        Back to Home
                                   </button>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isOpenIdVerification"
          class="fixed inset-0 z-60"
          style="background: rgba(0, 0, 0, 0.75)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from "vue";
import eventBus from "plugins/event.js";
import { authStore } from "store/auth";
import { useKYCVeriff } from "composables/use-kyc-veriff.js";
import { getProvider } from "api/kyc.js";

import { useRouter } from "vue-router";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";

export default {
     setup() {
          const router = useRouter();
          const useAuthStore = authStore();

          const { verifflaunchWebSdk } = useKYCVeriff();

          const isOpenIdVerification = ref(false);
          const providerWillUse = ref(null);

          const useUtilitiesStore = utilitiesStore();

          const isComplete = ref(false);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const closeModal = () => {
               isOpenIdVerification.value = false;
               useUtilitiesStore.enableScroll();
               router.replace({});
          };

          const back2Home = () => {
               window.location.href = "/lobby";
          };

          onMounted(async () => {
               eventBus.on("open:idverification", () => {
                    getProviderWillbeUse();
                    useUtilitiesStore.disableScroll();
                    isOpenIdVerification.value = true;
               });

               eventBus.on("close:idverification", () => {
                    useUtilitiesStore.enableScroll();
                    isOpenIdVerification.value = false;
               });
          });

          onUnmounted(() => {
               eventBus.off("open:idverification");
               eventBus.off("close:idverification");
          });

          async function getProviderWillbeUse() {
               await getProvider().then((res) => {
                    console.log(res);
                    if (res.evaluation !== "done") {
                         providerWillUse.value = res.use;
                    }
               });
          }

          const InitSdk = async () => {
               verifflaunchWebSdk(isUser.value);
          };

          watch([isOpenIdVerification, providerWillUse], async (newVal) => {
               if (newVal[0] == true && newVal[1]) {
                    InitSdk();
               }
          });

          return {
               isOpenIdVerification,
               closeModal,
               isComplete,
               back2Home,
               baseUrl
          };
     }
};
</script>
