import request from "utils/request.js";
let prefix = "/user/kyc/sumsub";
export function uploadIdKyc(data) {
     return request({
          url: prefix + "/upload",
          method: "get",
          data: data
     });
}

export function getKYC() {
     return request({
          url: prefix + "/token",
          method: "get"
     });
}

export function setApplicantID(data) {
     return request({
          url: prefix + "/applicant",
          method: "post",
          data: data
     });
}

export function kycStatus(data) {
     return request({
          url: prefix + "/status",
          method: "get",
          params: data
     });
}
