import request from "@/utils/request";

export function getDaily(type = "") {
     return request({
          url: "/user/bonus/daily?type=" + type,
          method: "get"
     });
}

export function getWelcome() {
     return request({
          url: "/user/bonus/welcome",
          method: "get"
     });
}

export function getActiveDailyBonus() {
     return request({
          url: "/user/bonus/daily_active",
          method: "get"
     });
}

export function claimDaily(type = "") {
     return request({
          url: "/user/bonus/daily/claim",
          method: "post",
          params: {
               type: type
          }
     });
}

export function claimWelcome() {
     return request({
          url: "/user/bonus/welcome/claim",
          method: "post"
     });
}

export function freeGC() {
     return request({
          url: "/user/bonus/freegcc/claim",
          method: "post"
     });
}

export function getReferralStats() {
     return request({
          url: "/user/bonus/referral/stats",
          method: "get"
     });
}

export function addReferralStats(data) {
     return request({
          url: "/referral/stats",
          method: "get",
          params: data
     });
}

export function getBday() {
     return request({
          url: "/user/bonus/birthday",
          method: "get"
     });
}

export function claimBday() {
     return request({
          url: "/user/bonus/birthday/claim",
          method: "post"
     });
}
