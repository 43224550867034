<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenProcessFailed"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 flex justify-center items-center"
          >
               <div
                    class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-md max-h-full tracking-wider m-auto"
               >
                    <!-- Modal content -->
                    <div class="relative error-payment rounded-lg shadow">
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <!-- Modal header -->
                         <div class="text-center divide-gray-70 py-10 mb-2">
                              <div
                                   class="px-2 sm:px-4 flex flex-col justify-center items-center"
                              >
                                   <div
                                        class="flex flex-row items-center gap-2 text-4xl"
                                   >
                                        <span class="icon-error"></span>
                                        <h3
                                             class="text-white whitespace-nowrap font-helvetica tracking-wide text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl xl:text-5xl 2xl:text-4xl leading-xl xs:leading-3xl sm:leading-5xl md:leading-5xl lg:leading-4xl xl:leading-5xl 2xl:leading-4xl uppercase"
                                        >
                                             OOPS!
                                        </h3>
                                   </div>
                                   <p
                                        class="mt-8 text-white font-roboto font-medium tracking-wide text-center text-sm xs:text-lg sm:text-xl md:text-2xl lg:text-lg xl:text-2xl 2xl:text-xl"
                                   >
                                        {{ message }}
                                   </p>
                                   <img
                                        class="w-auto mx-auto mt-8"
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/modal/error.png'
                                        "
                                        alt=""
                                   />
                                   <div
                                        @click="close()"
                                        class="rounded-full mt-5 w-max font-roboto font-extrabold tracking-wider px-10 py-3 bg-error text-white text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer shadow-box"
                                   >
                                        Close
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isOpenProcessFailed"
          class="fixed inset-0 z-60"
          style="background: rgba(0, 0, 0, 0.75)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";

export default {
     setup() {
          const useUtilitiesStore = utilitiesStore();
          const isOpenProcessFailed = ref(false);
          const message = ref("");
          const onClick = ref(null);

          const togglePaymentFailed = () => {
               isOpenProcessFailed.value = !isOpenProcessFailed.value;
          };

          const closeModal = () => {
               isOpenProcessFailed.value = false;
               useUtilitiesStore.enableScroll();
          };

          const close = () => {
               closeModal();
               if (onClick.value) {
                    onClick.value();
               }
          };

          onMounted(async () => {
               eventBus.on("open:process_failed", (data) => {
                    const { msg = "", onClick = null } = data;
                    message.value = msg;
                    onClick.value = onClick;
                    useUtilitiesStore.disableScroll();
                    togglePaymentFailed();
               });
          });

          onUnmounted(() => {
               eventBus.off("open:process_failed");
          });

          return {
               isOpenProcessFailed,
               closeModal,
               baseUrl,
               message,
               close
          };
     }
};
</script>
