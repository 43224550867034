<template>
     <a
          v-if="welcomeBonus"
          @click="openWelcomeBonus()"
          class="flex items-center py-2 rounded-lg group cursor-pointer get-coins"
     >
          <div
               class="w-full h-10 sm:h-11 flex flex-col items-center justify-center gap-0 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-2 welcome-bonus-container text-secondary-3 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
          >
               <span
                    class="cursor-pointer font-roboto text-xs sm:text-sm font-extrabold"
                    >WELCOME BONUS</span
               >
               <span
                    class="w-full text-changing-color-animation tracking-wider text-center font-roboto text-xs sm:text-sm font-extrabold -mt-1"
               >
                    CLAIM NOW
               </span>
          </div>
     </a>
</template>
<script>
import { bonusStore } from "store/bonus.js";
import eventBus from "plugins/event.js";
import { baseUrl } from "config/services.js";
import { useKYC } from "composables/use-kyc.js";
import { computed } from "vue";

export default {
     setup() {
          const useBonusStore = bonusStore();

          const { validateEveryAction } = useKYC();

          const welcomeBonus = computed(() => {
               return useBonusStore.welcomeBonus;
          });

          const openWelcomeBonus = () => {
               if (validateEveryAction("bonus") == true) {
                    return;
               }

               eventBus.emit("open:welcomebonus");
          };

          return {
               openWelcomeBonus,
               baseUrl,
               welcomeBonus
          };
     }
};
</script>
