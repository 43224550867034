<template>
     <FilterGame
          :onSearch="onSearch"
          :onClear="onClear"
          :isDropdown="isDropdown"
          :onDropdown="
               (val) => {
                    isDropdown = val != null ? val : !isDropdown;
                    onCloseDropdown(isDropdown);
               }
          "
          :selectedDropdown="selectedDropdown"
          :onSelect="onSelect"
          :hideDropdown="true"
          :params="params"
          :sortByOpen="sortByOpen"
          :onSortByOpen="
               (val) => {
                    sortByOpen = val != null ? val : !sortByOpen;
               }
          "
          :onSort="onSort"
     />
     <CategoryGame
          v-if="search == ''"
          :onSetActive="onSetActive"
          :active="active"
     />
     <div v-if="search == ''">
          <div
               v-for="(item, key) in groupData"
               :key="key"
          >
               <Recent
                    v-if="
                         isUser &&
                         ['recent', null].includes(active) &&
                         item.alias == 'recent'
                    "
                    :type="active == 'recent' ? 'paginate' : 'slider'"
                    :onSetActive="onSetActive"
                    :icon="item.icon"
               />
               <Top
                    v-if="
                         ['top-games', null].includes(active) &&
                         item.alias == 'top-games'
                    "
                    :type="active == 'top-games' ? 'paginate' : 'slider'"
                    :onSetActive="onSetActive"
                    :icon="item.icon"
               />
               <Winner
                    v-if="
                         ['recent-winner', null].includes(active) &&
                         item.alias == 'recent-winner'
                    "
                    :type="active == 'recent-winner' ? 'paginate' : 'slider'"
                    :onSetActive="onSetActive"
                    :icon="item.icon"
               />
               <Favorite
                    v-if="
                         isUser &&
                         ['favorite', null].includes(active) &&
                         item.alias == 'favorite'
                    "
                    :type="active == 'favorite' ? 'paginate' : 'slider'"
                    :onSetActive="onSetActive"
                    :icon="item.icon"
               />
               <New
                    v-if="['new', null].includes(active) && item.alias == 'new'"
                    :type="active == 'new' ? 'paginate' : 'slider'"
                    :onSetActive="onSetActive"
                    :icon="item.icon"
               />
               <Custom
                    v-if="item.type == 1"
                    :game_type="item['alias']"
                    :type="
                         active == item['alias']
                              ? 'paginate'
                              : active == null
                                ? 'slider'
                                : ''
                    "
                    :onSetActive="onSetActive"
                    :icon="item.icon"
                    :is_game_category="item.type == 1"
               />
          </div>
     </div>
     <Result
          v-else
          :search="search"
     />
     <div v-if="search == ''">
          <div
               v-for="(item, key) in groupData"
               :key="key"
          >
               <All
                    v-if="
                         ['all-games', null].includes(active) &&
                         item.alias == 'all-games'
                    "
                    :onSetActive="onSetActive"
                    :isLobby="active == null"
                    :game_type="item.alias"
                    :icon="item.icon"
               />
          </div>
     </div>
</template>
<script>
import {
     computed,
     onMounted,
     reactive,
     ref,
     watch,
     onBeforeUnmount
} from "vue";
import { gamesStore } from "store/games";
import { authStore } from "store/auth.js";
import _ from "underscore";
import { useFormatter } from "composables/use-formatter.js";
import { useRoute, useRouter } from "vue-router";
import { useScroll } from "composables/use-scroll.js";
import { baseUrl } from "config/services.js";
import { utilitiesStore } from "store/utilities.js";
import FilterGame from "./filter/index.vue";
import CategoryGame from "./category/index.vue";
import Recent from "./list/recent/index.vue";
import Favorite from "./list/favorite/index.vue";
import New from "./list/new/index.vue";
// import Exclusive from "./list/exclusive/index.vue";
import Top from "./list/top/index.vue";
import All from "./list/all/index.vue";
import Custom from "./list/custom/index.vue";
import Result from "./list/result/index.vue";
import eventBus from "plugins/event.js";
import Winner from "./list/winner/index.vue";
import device from "middleware/device.js";

import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
     components: {
          FilterGame,
          CategoryGame,
          Recent,
          Favorite,
          Top,
          All,
          Custom,
          Result,
          New,
          Winner
     },
     setup() {
          const { money } = useFormatter();
          const route = useRoute();
          const router = useRouter();
          const { scrollTop } = useScroll();
          const { isIOS } = device();

          const banner = ref(null);

          const isDropdown = ref(false);

          const search = ref("");

          const params = reactive({
               page: 1,
               itemsPerPage: 21,
               game_type: [],
               game_promo: 0,
               game_name: "",
               provider_id: [],
               sortType: "release_date"
          });

          const selfExclusion = ref(null);

          const oldSelectedDropdown = ref([]);
          const selectedDropdown = ref([]);

          // all
          const active = ref(null);

          const all_count = ref(0);

          const loading = ref(false);

          const sortByOpen = ref(false);

          // const categorySetionList = ref([]);
          const categorySetionOnLoad = ref([]);
          // const categorySetionIndex = ref(0);

          const useAuthStore = authStore();
          const useGamesStore = gamesStore();
          const useUtilitiesStore = utilitiesStore();

          let timer,
               timeoutVal = 1000;

          const gameFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameFilter);
               for (let index = 0; index < obj.length; index++) {
                    params[obj[index]] = useGamesStore.gameFilter[obj[index]];
               }

               active.value = useGamesStore.gameFilter["active"];

               return useGamesStore.gameFilter;
          });

          const groupData = computed(() => {
               if (useGamesStore.groups) {
                    if (route.path.split("/")[2] == "type") {
                         const find = _.find(useGamesStore.groups, (params) => {
                              return (
                                   params["name"].toLowerCase() ==
                                   route.path.split("/")[3]
                              );
                         });
                         if (find != 0) {
                              active.value = find["alias"];
                              params.game_type = [find["name"].toLowerCase()];
                         }
                    }
               }
               return useGamesStore.groups || null;
          });

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const errorImages = ref([]);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const preloading = computed(() => {
               return useUtilitiesStore.loading;
          });

          const onImageError = (e) => {
               let temp = errorImages.value;
               temp.push(parseInt(e.target.alt));

               const uniq = _.uniq(temp);

               errorImages.value = uniq;

               e.target.src = "assets/img/games/default.png";
          };

          const onSetActive = async (data) => {
               active.value = data;
               let query = {};

               const filter = _.filter(providerData.value, function (provider) {
                    return selectedDropdown.value.includes(provider.id);
               });

               const validate = _.filter(filter, function (item) {
                    return item.name != "All";
               });

               const pluck = _.pluck(validate, "name");

               if (data != null) {
                    if (pluck != null) {
                         query["provider"] = pluck
                              .join(",")
                              .toLowerCase()
                              .replaceAll(" ", "-");
                    }

                    query["type"] = data;
                    router.replace({ query: query });
                    gameCases(active.value, "onInit");
               } else {
                    router.replace({ query: query });
                    setTimeout(() => {
                         onScrollTrigger();
                    }, 1000);
               }
               window.scrollTo({
                    top: 0, // Scroll to the top
                    behavior: "smooth" // Smooth scroll animation
               });
               params["active"] = active.value;
               useGamesStore.setGameFilter(params, active.value);
          };

          const onSearch = (query) => {
               window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
               timer = window.setTimeout(async () => {
                    if (query.toLowerCase() == "") {
                         search.value = "";
                         useGamesStore.clearResult();
                    }

                    search.value = query.toLowerCase();

                    const temp = {
                         ...params,
                         game_name: query.toLowerCase(),
                         game_promo: 0,
                         game_type: [],
                         page: 1,
                         itemsPerPage: 21
                    };

                    await useGamesStore.getGames(temp, "result", true, true);
               }, timeoutVal);
          };

          const onClear = () => {
               search.value = "";
               useGamesStore.clearResult();
          };

          const onSelect = async (id) => {
               const data = selectedDropdown.value;

               if (id == 0) {
                    if (Object.keys(data).length == 0) {
                         const pluck = _.pluck(providerData.value, "id");
                         selectedDropdown.value = pluck;
                         params.provider_id = pluck;
                    } else {
                         if (
                              Object.keys(data).length !=
                              providerData.value.length
                         ) {
                              const pluck = _.pluck(providerData.value, "id");
                              selectedDropdown.value = pluck;
                              params.provider_id = pluck;
                         } else {
                              selectedDropdown.value = [];
                              params.provider_id = [];
                         }
                    }
               } else {
                    if (data.includes(id)) {
                         const temp = [...data];

                         if (temp.includes(0)) {
                              temp.splice(temp.indexOf(0), 1);
                         }
                         temp.splice(temp.indexOf(id), 1);
                         selectedDropdown.value = temp;
                         params.provider_id = temp;
                    } else {
                         const temp = [...data];

                         temp.push(id);

                         if (temp.length == providerData.value.length - 1) {
                              selectedDropdown.value = [0, ...temp];
                         } else {
                              selectedDropdown.value = temp;
                         }
                         params.provider_id = temp;
                    }
               }

               params.page = 1;
               useGamesStore.setGameFilter(params, active.value);
          };

          function arraysHaveSameValues(arr1, arr2) {
               if (arr1.length !== arr2.length) return false;

               const sorted1 = [...arr1].sort();
               const sorted2 = [...arr2].sort();

               return sorted1.every((value, index) => value === sorted2[index]);
          }

          const onCloseDropdown = async (isClose) => {
               if (isClose) {
                    oldSelectedDropdown.value = selectedDropdown.value;
                    return;
               }

               const isSameValue = arraysHaveSameValues(
                    oldSelectedDropdown.value,
                    selectedDropdown.value
               );

               if (isSameValue) {
                    return;
               }

               if (search.value != "") {
                    const temp = {
                         ...params,
                         game_name: search.value.toLowerCase(),
                         game_promo: 0,
                         game_type: [],
                         page: 1,
                         itemsPerPage: 21
                    };

                    useGamesStore.getGames(
                         temp,
                         "result",
                         true,
                         false,
                         "dropdown"
                    );

                    return;
               }

               if (groupData.value) {
                    const filter = _.filter(
                         providerData.value,
                         function (provider) {
                              return selectedDropdown.value.includes(
                                   provider.id
                              );
                         }
                    );

                    const pluck = _.pluck(filter, "name");

                    let query = {};

                    if (active.value != null) {
                         query["type"] = active.value;
                    }

                    if (pluck != null) {
                         if (pluck.includes("All") || pluck.length == 0) {
                              router.replace({ query: query });
                         } else {
                              query["provider"] = pluck
                                   .join(",")
                                   .toLowerCase()
                                   .replaceAll(" ", "-");
                              router.replace({ query: query });
                         }
                         // gameCases(active.value, "onInit");
                    } else {
                         router.replace({ query: query });
                         setTimeout(() => {
                              // onScrollTrigger();
                         }, 1000);
                    }

                    window.scrollTo({
                         top: 0, // Scroll to the top
                         behavior: "smooth" // Smooth scroll animation
                    });

                    for (
                         let index = 0;
                         index < groupData.value.length;
                         index++
                    ) {
                         useGamesStore.resetGames(groupData.value[index].alias);

                         if (isUser.value) {
                              if (groupData.value[index].alias == "recent") {
                                   if (
                                        active.value == null ||
                                        active.value == "recent"
                                   ) {
                                        const tempRecent = {
                                             ...params,
                                             game_type: [],
                                             itemsPerPage: 21
                                        };

                                        delete tempRecent["sortType"];

                                        await useGamesStore.getRecent(
                                             tempRecent,
                                             true,
                                             false,
                                             "dropdown"
                                        );
                                   }
                              } else if (
                                   groupData.value[index].alias == "favorite"
                              ) {
                                   if (
                                        active.value == null ||
                                        active.value == "favorite"
                                   ) {
                                        const tempFavs = {
                                             ...params,
                                             game_type: [],
                                             itemsPerPage: 21
                                        };

                                        await useGamesStore.getFavorites(
                                             tempFavs,
                                             true,
                                             false,
                                             "dropdown"
                                        );
                                   }
                              }
                         }

                         if (groupData.value[index].alias == "all-games") {
                              if (
                                   active.value == null ||
                                   active.value == "all-games"
                              ) {
                                   const temp = {
                                        ...params,
                                        game_type: [],
                                        itemsPerPage: 48
                                   };

                                   await useGamesStore.getGames(
                                        temp,
                                        "all-games",
                                        true,
                                        false,
                                        "dropdown"
                                   );
                              }
                         } else if (groupData.value[index].alias == "new") {
                              if (
                                   active.value == null ||
                                   active.value == "new"
                              ) {
                                   const newTemp = {
                                        ...params,
                                        game_type: [],
                                        game_new: 1,
                                        itemsPerPage: 21,
                                        sortType: "release_date"
                                   };

                                   await useGamesStore.getGames(
                                        newTemp,
                                        "new",
                                        true,
                                        false,
                                        "dropdown"
                                   );
                              }
                         } else if (
                              groupData.value[index].alias == "top-games"
                         ) {
                              if (
                                   active.value == null ||
                                   active.value == "top-games"
                              ) {
                                   const temp = {
                                        ...params,
                                        page: 1,
                                        itemsPerPage: 100,
                                        sortType: "popularity"
                                   };

                                   await useGamesStore.getPopular(temp, true);
                              }
                         } else if (groupData.value[index].type == 1) {
                              if (
                                   active.value == null ||
                                   active.value ==
                                        groupData.value[index]["alias"]
                              ) {
                                   const temp = {
                                        ...params,
                                        game_type: [
                                             groupData.value[index][
                                                  "name"
                                             ].toLowerCase()
                                        ],
                                        itemsPerPage: 21
                                   };
                                   await useGamesStore.getGames(
                                        temp,
                                        groupData.value[index]["alias"],
                                        true,
                                        false,
                                        "dropdown"
                                   );
                              }
                         }
                    }
               }
          };

          const onSort = async (value) => {
               params.sortType = value;
               params.page = 1;

               useGamesStore.setGameFilter(params, active.value);

               sortByOpen.value = false;

               if (search.value != "") {
                    const temp = {
                         ...params,
                         game_name: search.value.toLowerCase(),
                         game_promo: 0,
                         game_type: [],
                         page: 1,
                         itemsPerPage: 21
                    };

                    useGamesStore.getGames(temp, "result", true, false, "sort");

                    return;
               }

               gameCases(active.value, "onInit");
          };

          const openLogin = () => {
               router.push({ name: "login" });
          };

          const gotoPromotion = (item) => {
               router.push({
                    path:
                         "/promotion/detail/" +
                         item.title.replaceAll(" ", "-") +
                         "-" +
                         item.title_highlight.replaceAll(" ", "-") +
                         "-" +
                         item.id
               });
          };

          // const handleScroll = () => {
          //      const scroll = getScroll();

          //      checkSection(scroll)
          // }

          // const getScroll = () => {
          //      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          //      const viewportHeight = window.innerHeight;
          //      const pageHeigth = document.documentElement.scrollHeight;

          //      return {
          //           currentScroll: scrollTop + (viewportHeight * 1.5),
          //           pageHeigth: pageHeigth
          //      }
          // };

          const onScrollTrigger = () => {
               // ScrollTrigger.killAll();
               ScrollTrigger.clearScrollMemory();

               if (groupData.value) {
                    // const temp = []

                    for (
                         let index = 0;
                         index < groupData.value?.length;
                         index++
                    ) {
                         const trigger =
                              groupData.value[index]["alias"] == "all-games" ||
                              active.value != null
                                   ? "#paginate-" +
                                     groupData.value[index]["alias"]
                                   : "#slider-" +
                                     groupData.value[index]["alias"];
                         const el = document.querySelector(trigger);

                         if (el != null) {
                              ScrollTrigger.create({
                                   trigger: trigger,
                                   start: "top 150%",
                                   end: "bottom",
                                   // markers: true,
                                   refreshPriority: 1,
                                   onToggle: (self) => {
                                        if (self.isActive) {
                                             if (
                                                  groupData.value[index][
                                                       "alias"
                                                  ] == "recent"
                                             ) {
                                                  if (
                                                       !categorySetionOnLoad.value.includes(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"]
                                                       )
                                                  ) {
                                                       gameCases(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"],
                                                            "onScroll"
                                                       );
                                                  }
                                             } else if (
                                                  groupData.value[index][
                                                       "alias"
                                                  ] == "favorite"
                                             ) {
                                                  if (
                                                       !categorySetionOnLoad.value.includes(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"]
                                                       )
                                                  ) {
                                                       gameCases(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"],
                                                            "onScroll"
                                                       );
                                                  }
                                             } else if (
                                                  groupData.value[index][
                                                       "alias"
                                                  ] == "all-games"
                                             ) {
                                                  if (
                                                       !categorySetionOnLoad.value.includes(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"]
                                                       )
                                                  ) {
                                                       gameCases(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"],
                                                            "onScroll"
                                                       );
                                                  }
                                             } else if (
                                                  groupData.value[index][
                                                       "alias"
                                                  ] == "new"
                                             ) {
                                                  if (
                                                       !categorySetionOnLoad.value.includes(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"]
                                                       )
                                                  ) {
                                                       gameCases(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"],
                                                            "onScroll"
                                                       );
                                                  }
                                             } else if (
                                                  groupData.value[index][
                                                       "alias"
                                                  ] == "top-games"
                                             ) {
                                                  if (
                                                       !categorySetionOnLoad.value.includes(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"]
                                                       )
                                                  ) {
                                                       gameCases(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"],
                                                            "onScroll"
                                                       );
                                                  }
                                             } else if (
                                                  groupData.value[index][
                                                       "alias"
                                                  ] == "recent-winner"
                                             ) {
                                                  if (
                                                       !categorySetionOnLoad.value.includes(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"]
                                                       )
                                                  ) {
                                                       gameCases(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"],
                                                            "onScroll"
                                                       );
                                                  }
                                             } else if (
                                                  groupData.value[index][
                                                       "type"
                                                  ] == true
                                             ) {
                                                  if (
                                                       !categorySetionOnLoad.value.includes(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"]
                                                       )
                                                  ) {
                                                       gameCases(
                                                            groupData.value[
                                                                 index
                                                            ]["alias"],
                                                            "onScroll"
                                                       );
                                                  }
                                             }
                                        }
                                   }
                              });
                         }
                    }
               }
          };

          const gameCases = (key, type) => {
               switch (key) {
                    case "recent":
                         if (isUser.value) {
                              const tempRecent = {
                                   ...params,
                                   game_type: [],
                                   itemsPerPage: 21
                              };

                              delete tempRecent["sortType"];

                              if (type) {
                                   categorySetionOnLoad.value.push(key);

                                   if (!isIOS()) {
                                        ScrollTrigger.refresh();
                                   }
                              }

                              getRecent(tempRecent, true, false);
                         }
                         break;
                    case "favorite":
                         if (isUser.value) {
                              const tempFavs = {
                                   ...params,
                                   game_type: [],
                                   itemsPerPage: 21
                              };

                              if (type) {
                                   categorySetionOnLoad.value.push(key);

                                   if (!isIOS()) {
                                        ScrollTrigger.refresh();
                                   }
                              }

                              getFavorites(tempFavs, true, false);
                         }
                         break;
                    case "all-games":
                         const tempAll = {
                              ...params,
                              game_type: [],
                              itemsPerPage: 48
                         };

                         if (type) {
                              categorySetionOnLoad.value.push(key);

                              if (!isIOS()) {
                                   ScrollTrigger.refresh();
                              }
                         }

                         getGamesCategory(tempAll, "all-games");

                         break;
                    case "new":
                         const newTemp = {
                              ...params,
                              game_type: [],
                              itemsPerPage: 21,
                              game_new: 1,
                              sortType: "release_date"
                         };

                         if (type) {
                              categorySetionOnLoad.value.push(key);

                              if (!isIOS()) {
                                   ScrollTrigger.refresh();
                              }
                         }

                         getGamesCategory(newTemp, "new");
                         break;
                    case "top-games":
                         const tempPopularity = {
                              ...params,
                              page: 1,
                              itemsPerPage: 100,
                              sortType: "popularity"
                         };

                         if (type) {
                              categorySetionOnLoad.value.push(key);

                              if (!isIOS()) {
                                   ScrollTrigger.refresh();
                              }
                         }

                         getPopular(tempPopularity);
                         break;
                    case "recent-winner":
                         useGamesStore.getWinner();

                         if (type) {
                              categorySetionOnLoad.value.push(key);

                              if (!isIOS()) {
                                   ScrollTrigger.refresh();
                              }
                         }

                         break;
                    default:
                         const tempCategory = {
                              ...params,
                              itemsPerPage: 21,
                              game_type: [
                                   key.replaceAll("-", " ").toLowerCase()
                              ]
                         };

                         if (type) {
                              categorySetionOnLoad.value.push(key);

                              if (!isIOS()) {
                                   ScrollTrigger.refresh();
                              }
                         }

                         getGamesCategory(tempCategory, key);
                         break;
               }
          };

          const getRecent = async (tempRecent, isReset, isPaginate) => {
               await useGamesStore.getRecent(tempRecent, isReset, isPaginate);
          };

          const getFavorites = async (tempFavs, isReset, isPaginate) => {
               await useGamesStore.getFavorites(tempFavs, isReset, isPaginate);
          };

          const getPopular = async (temp) => {
               await useGamesStore.getPopular(temp);
          };

          const getGamesCategory = async (temp, type) => {
               await useGamesStore.getGames(temp, type);
          };

          onMounted(async () => {
               eventBus.on("back_to_lobby", () => {
                    onClear();
                    onSetActive(null);
               });

               if (!preloading.value) {
                    loading.value = true;
               }

               scrollTop();

               await useGamesStore.getProvider();
               await useGamesStore.getGameType();

               setTimeout(() => {
                    onScrollTrigger();
               }, 1000);

               const path = route.fullPath.split("?");
               const query = path[1]?.split("&");

               const provider = _.find(query, function (params) {
                    return params.includes("provider");
               });

               if (provider) {
                    const split = provider.split("=");
                    const name = split[1].replaceAll("-", " ").split(",");

                    let filter = [];

                    if (!name.includes("all")) {
                         filter = _.filter(providerData.value, (value) => {
                              return name.includes(value["name"].toLowerCase());
                         });
                    }

                    if (filter.length != 0) {
                         params.provider_id = _.pluck(filter, "id");
                         selectedDropdown.value = params.provider_id;
                    } else {
                         params.provider_id = _.pluck(providerData.value, "id");
                         selectedDropdown.value = params.provider_id;
                    }
               } else {
                    params.provider_id = _.pluck(providerData.value, "id");
                    selectedDropdown.value = params.provider_id;
               }

               const type = _.find(query, function (params) {
                    return params.includes("type");
               });

               if (type) {
                    const split = type.split("=");
                    const pluck = _.pluck(groupData.value, "alias");

                    if (isUser.value) {
                         const find = _.find(pluck, function (params) {
                              return params == split[1].toLowerCase();
                         });

                         if (find) {
                              active.value = split[1].toLowerCase();
                         }
                    } else {
                         const find = _.find(pluck, function (params) {
                              return params == split[1].toLowerCase();
                         });

                         if (find) {
                              active.value = split[1].toLowerCase();
                         }
                    }

                    params["active"] = active.value;
                    gameCases(active.value, "onInit");
               }

               useGamesStore.setGameFilter(params, active.value);

               // if (groupData.value) {
               //      for (
               //           let index = 0;
               //           index < groupData.value.length;
               //           index++
               //      ) {
               //           if (isUser.value) {
               //                if (groupData.value[index].alias == "recent") {
               //                     const tempRecent = {
               //                          ...params,
               //                          game_type: [],
               //                          itemsPerPage: 21
               //                     };

               //                     delete tempRecent["sortType"];

               //                     await useGamesStore.getRecent(
               //                          tempRecent,
               //                          true,
               //                          false
               //                     );
               //                } else if (
               //                     groupData.value[index].alias == "favorite"
               //                ) {
               //                     const tempFavs = {
               //                          ...params,
               //                          game_type: [],
               //                          itemsPerPage: 21
               //                     };

               //                     await useGamesStore.getFavorites(
               //                          tempFavs,
               //                          true,
               //                          false
               //                     );
               //                }
               //           }

               //           if (groupData.value[index].alias == "all-games") {
               //                const temp = {
               //                     ...params,
               //                     game_type: [],
               //                     itemsPerPage: 48
               //                };

               //                await useGamesStore.getGames(temp, "all-games");
               //           } else if (groupData.value[index].alias == "new") {
               //                const newTemp = {
               //                     ...params,
               //                     game_type: [],
               //                     itemsPerPage: 21,
               //                     game_new: 1,
               //                     sortType: "release_date"
               //                };

               //                await useGamesStore.getGames(newTemp, "new");
               //           } else if (
               //                groupData.value[index].alias == "top-games"
               //           ) {
               //                const temp = {
               //                     ...params,
               //                     page: 1,
               //                     itemsPerPage: 21,
               //                     sortType: "popularity"
               //                };

               //                await useGamesStore.getPopular(temp);
               //           } else if (groupData.value[index].type == 1) {
               //                const temp = {
               //                     ...params,
               //                     itemsPerPage: 21,
               //                     game_type: [
               //                          groupData.value[index][
               //                               "name"
               //                          ].toLowerCase()
               //                     ]
               //                };
               //                await useGamesStore.getGames(
               //                     temp,
               //                     groupData.value[index]["alias"]
               //                );
               //           }
               //      }
               // }
          });

          onBeforeUnmount(async () => {
               // ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
               ScrollTrigger.clearScrollMemory();
          });

          watch(groupData, (newVal) => {
               if (newVal) {
                    if (route.path.split("/")[2] == "type") {
                         const find = _.find(newVal, (params) => {
                              return (
                                   params["name"].toLowerCase() ==
                                   route.path.split("/")[3]
                              );
                         });

                         if (find.length != 0) {
                              active.value = find["alias"];
                              params.game_type = [find["name"].toLowerCase()];
                         }
                    }
               }
          });

          watch(gameFilter, (newVal) => {
               if (newVal) {
                    const obj = Object.keys(newVal);
                    for (let index = 0; index < obj.length; index++) {
                         params[obj[index]] = newVal[obj[index]];
                    }

                    active.value = newVal["active"];
               }
          });

          // watch(selectedDropdown, (newVal, oldVal) => {
          //      // if (newVal) {
          //           console.log(oldVal);
          //           console.log(newVal);
          //      // }
          // });

          return {
               isDropdown,
               active,
               onSetActive,
               params,
               onImageError,
               isUser,
               onSearch,
               errorImages,
               selectedDropdown,
               onSelect,
               openLogin,
               all_count,
               money,
               loading,
               banner,
               selfExclusion,
               sortByOpen,
               onSort,
               baseUrl,
               gotoPromotion,
               groupData,
               search,
               onClear,
               providerData,
               onCloseDropdown
          };
     }
};
</script>
