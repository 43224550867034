import request from "utils/request.js";
let prefix = "/user/kyc/veriff";
export function getToken() {
     return request({
          url: prefix + "/token",
          method: "get"
     });
}
export function initVeriffKyc(payload) {
     return request({
          url: prefix + "/init-kyc",
          method: "post",
          data: {
               payload: payload
          }
     });
}
