<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenPaymentSuccess"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 flex justify-center items-center"
          >
               <div
                    class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-md max-h-full tracking-wider m-auto"
               >
                    <!-- Modal content -->
                    <div
                         id="depositSuccessPayment"
                         class="relative success-payment rounded-lg shadow"
                    >
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <!-- Modal header -->
                         <div class="text-center divide-gray-70 py-10 mb-2">
                              <div
                                   class="px-2 sm:px-4 flex flex-col justify-center items-center"
                              >
                                   <div
                                        class="flex flex-row items-center gap-2 text-4xl"
                                   >
                                        <span class="icon-success"></span>
                                        <h3
                                             class="text-white whitespace-nowrap font-helvetica tracking-wide text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl xl:text-5xl 2xl:text-4xl leading-xl xs:leading-3xl sm:leading-5xl md:leading-5xl lg:leading-4xl xl:leading-5xl 2xl:leading-4xl uppercase"
                                        >
                                             YEEHA!
                                        </h3>
                                   </div>
                                   <p
                                        class="mt-8 text-white font-roboto font-medium tracking-wide text-center text-sm xs:text-lg sm:text-xl md:text-2xl lg:text-lg xl:text-2xl 2xl:text-xl"
                                   >
                                        {{ message }}
                                   </p>
                                   <img
                                        class="w-auto mx-auto mt-8"
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/modal/success.png'
                                        "
                                        alt=""
                                   />
                                   <div
                                        @click="closeModal()"
                                        class="rounded-full mt-5 w-max font-roboto font-extrabold tracking-wider px-10 py-3 bg-success text-white text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer shadow-box"
                                   >
                                        Proceed
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isOpenPaymentSuccess"
          class="fixed inset-0 z-60"
          style="background: rgba(0, 0, 0, 0.75)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { coinsStore } from "store/coins";
import { useRouter } from "vue-router";
import { baseUrl } from "config/services.js";

export default {
     props: {
          successCoins: {
               type: Object,
               required: true
          },
          message: {
               type: String,
               default: "Your payment has been received",
               required: false
          }
     },
     setup() {
          const useUtilitiesStore = utilitiesStore();
          const useCoinsStore = coinsStore();
          const isOpenPaymentSuccess = ref(false);
          const router = useRouter();

          const GC = ref(0);
          const SC = ref(0);

          const togglePaymentSuccess = () => {
               isOpenPaymentSuccess.value = !isOpenPaymentSuccess.value;
          };

          const closeModal = () => {
               isOpenPaymentSuccess.value = false;
               router.push({
                    name: "thanks",
                    params: { gc: GC.value, sc: SC.value }
               });
               useUtilitiesStore.enableScroll();
          };

          onMounted(async () => {
               eventBus.on("open:payment_success", (data) => {
                    // console.log(data);
                    if (data) {
                         GC.value = data.gc;
                         SC.value = data.sc;
                    }
                    useCoinsStore.getCoins(true);
                    useUtilitiesStore.disableScroll();
                    togglePaymentSuccess();
               });
          });

          onUnmounted(() => {
               eventBus.off("open:payment_success");
          });

          return {
               isOpenPaymentSuccess,
               closeModal,
               baseUrl
          };
     }
};
</script>
