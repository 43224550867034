<template>
     <InertiaHead title="Moozi VIP Form"></InertiaHead>
     <div class="home-wrapper mt-0">
          <div
               class="banner flex bg-no-repeat w-full font-helveticaLight flex-col justify-center items-center relative"
          >
               <div class="text-center">
                    <div
                         class="pt-18 md:pt-22 lg:pt-28 text-white font-helvetica tracking-wide text-center text-4xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-3xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl uppercase px-2"
                    >
                         MOOZI VIP FORM
                    </div>
               </div>
               <div class="w-full flex justify-center items-center pb-20">
                    <div class="py-14 sm:py-16 md:py-20 lg:py-28">
                         <div
                              class="bg-white rounded-xl py-6 sm:py-12 md:py-20 lg:py-28 px-5 xs:px-7 sm:px-10 lg:px-20 xl:px-24 2xl:px-48 shadow-xl"
                         >
                              <p
                                   class="mb-0 text-sm sm:text-base lg:text-lg text-justify font-bold"
                              >
                                   Dear VIP,
                              </p>
                              <br />
                              <p
                                   class="mb-0 text-sm sm:text-base lg:text-lg text-justify"
                              >
                                   Thank-you for your player loyalty! We would
                                   like to reward you with 100SC from Moozi. As
                                   part of this we consider you a VIP and as
                                   such would appreciate if you would fill out
                                   this routine form.
                              </p>
                              <br />
                              <hr />
                              <br />
                              <div
                                   v-for="(question, key) in vip_form"
                                   :key="key"
                                   class="mb-5"
                              >
                                   <p
                                        class="mb-2 text-sm sm:text-base lg:text-lg text-justify font-bold"
                                   >
                                        Question {{ key + 1 }}
                                        <span
                                             class="mb-2 text-sm sm:text-base lg:text-lg text-justify font-normal"
                                        >
                                             {{ question.question }}
                                        </span>
                                        <span
                                             class="ml-2"
                                             :class="
                                                  form.errors?.errors[
                                                       question['key']
                                                  ] != null
                                                       ? 'text-primal-red font-normal'
                                                       : ''
                                             "
                                        >
                                             {{
                                                  form.errors?.errors[
                                                       question["key"]
                                                  ] != null
                                                       ? "Required*"
                                                       : ""
                                             }}
                                        </span>
                                   </p>
                                   <div
                                        v-if="question.input == 'dropdown'"
                                        class="ml-5 relative"
                                   >
                                        <searchableSelect
                                             :form="form"
                                             :options="
                                                  generateObject(
                                                       question['options']
                                                  )
                                             "
                                             v-model:selectedOption="
                                                  form[question['key']]
                                             "
                                             :optionLabel="'label'"
                                             :optionValue="'value'"
                                             :placeholder="'Select an option'"
                                        />
                                        <div
                                             v-if="
                                                  form[question['key']] ==
                                                  'other'
                                             "
                                             class="mt-3"
                                        >
                                             <div
                                                  class="relative flex flex-col items-center"
                                             >
                                                  <input
                                                       type="text"
                                                       v-model="
                                                            form[
                                                                 'other_income_source'
                                                            ]
                                                       "
                                                       name="other_income_source"
                                                       id="other_income_source"
                                                       class="bg-white border border-background-3 text-shade-2 text-base rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2"
                                                       :class="
                                                            form.errors?.errors[
                                                                 'other_income_source'
                                                            ] != null
                                                                 ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                                                 : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                                                       "
                                                       @change="
                                                            clearFormError(
                                                                 'other_income_source'
                                                            )
                                                       "
                                                       placeholder=""
                                                  />
                                                  <span
                                                       :class="
                                                            form.errors?.errors[
                                                                 'other_income_source'
                                                            ] != null
                                                                 ? 'font-bold text-primal-red text-left w-full'
                                                                 : ''
                                                       "
                                                  >
                                                       {{
                                                            form.errors?.errors[
                                                                 "other_income_source"
                                                            ] != null
                                                                 ? "Required*"
                                                                 : ""
                                                       }}
                                                  </span>
                                             </div>
                                        </div>
                                   </div>
                                   <div
                                        v-if="question.input == 'checkbox'"
                                        class="ml-5 relative"
                                   >
                                        <div
                                             v-for="(
                                                  option, index
                                             ) in question.options"
                                             :key="index"
                                             class="w-full"
                                        >
                                             <div
                                                  v-if="
                                                       option.toLowerCase() !=
                                                       'other'
                                                  "
                                                  class="w-full flex items-center mt-1"
                                             >
                                                  <Check
                                                       @click="
                                                            markCheckbox(
                                                                 question[
                                                                      'key'
                                                                 ],
                                                                 option,
                                                                 'uncheck'
                                                            )
                                                       "
                                                       v-if="
                                                            form[
                                                                 question['key']
                                                            ]?.includes(option)
                                                       "
                                                       className="dropdown-trigger-svg"
                                                       class="mr-2 cursor-pointer"
                                                  />
                                                  <Uncheck
                                                       @click="
                                                            markCheckbox(
                                                                 question[
                                                                      'key'
                                                                 ],
                                                                 option,
                                                                 'check'
                                                            )
                                                       "
                                                       v-else
                                                       className="dropdown-trigger-svg"
                                                       class="mr-2 cursor-pointer"
                                                  />
                                                  <label
                                                       @click="
                                                            markCheckbox(
                                                                 question[
                                                                      'key'
                                                                 ],
                                                                 option,
                                                                 form[
                                                                      question[
                                                                           'key'
                                                                      ]
                                                                 ]?.includes(
                                                                      option
                                                                 )
                                                                      ? 'uncheck'
                                                                      : 'check'
                                                            )
                                                       "
                                                       :for="`option${index + 1}`"
                                                       class="mb-0 cursor-pointer text-shade-2 text-sm text-justify font-normal"
                                                  >
                                                       {{ option }}
                                                  </label>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div class="">
                                   <div
                                        class="mt-5 mb-2 flex flex-row items-center justify-center lg:justify-end gap-2"
                                   >
                                        <p
                                             class="text-xs sm:text-base text-center lg:text-right font-bold"
                                        >
                                             Draw your E-Signature here
                                        </p>
                                        <svg
                                             @click="clearSignature"
                                             class="cursor-pointer"
                                             width="24"
                                             height="24"
                                             viewBox="0 0 24 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                        >
                                             <path
                                                  d="M19.95 10.9998C19.6971 9.07056 18.7498 7.2996 17.2853 6.01844C15.8209 4.73728 13.9397 4.03377 11.9939 4.03959C10.0482 4.0454 8.17123 4.76016 6.71446 6.05005C5.25769 7.33994 4.32096 9.11654 4.07962 11.0473C3.83828 12.978 4.30887 14.9305 5.4033 16.5393C6.49774 18.1481 8.14098 19.3029 10.0254 19.7875C11.9099 20.2721 13.9064 20.0533 15.6411 19.172C17.3759 18.2908 18.73 16.8075 19.45 14.9998M19.95 19.9998V14.9998H14.95"
                                                  stroke="black"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                             />
                                        </svg>
                                   </div>

                                   <div
                                        class="flex justify-center lg:justify-end items-center"
                                   >
                                        <canvas
                                             id="signature-pad"
                                             class="signature-pad border border-background-3 rounded-lg"
                                             :class="
                                                  form.errors?.errors[
                                                       'signature'
                                                  ] != null
                                                       ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                                       : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                                             "
                                             height="200"
                                             width="300"
                                        ></canvas>
                                        <span
                                             :class="
                                                  form.errors?.errors[
                                                       'signature'
                                                  ] != null
                                                       ? 'font-bold text-primal-red'
                                                       : ''
                                             "
                                        >
                                             {{
                                                  form.errors?.errors[
                                                       "signature"
                                                  ] != null
                                                       ? "Required*"
                                                       : ""
                                             }}
                                        </span>
                                   </div>
                              </div>
                              <button
                                   @click="submitForm"
                                   type="submit"
                                   class="w-full mt-10 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-roboto font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg text-primary-1 bg-secondary-3"
                              >
                                   Submit
                              </button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import Check from "components/checkbox/check.vue";
import Uncheck from "components/checkbox/uncheck.vue";
import { onMounted, reactive } from "vue";
import Form from "vform";
import { ref } from "vue";
import SignaturePad from "signature_pad";
import { saveSignature as saveSignatureAPI } from "api/signature.js";
import { useToaster } from "composables/use-toaster.js";
import searchableSelect from "components/searchableselect/index.vue";
import { vip_form } from "constants/moozi-vip-form.js";
import _ from "underscore";

export default {
     components: {
          InertiaHead,
          Check,
          Uncheck,
          searchableSelect
     },
     setup() {
          const { successToast, errorToast } = useToaster();

          const form = reactive(
               new Form({
                    other_income_source: ""
               })
          );

          const signaturePad = ref(null);

          const generateObject = (options) => {
               const obj = _.map(options, function (params) {
                    return {
                         label: params,
                         value: params.toLowerCase()
                    };
               });

               return obj;
          };

          const markCheckbox = (key, option, type) => {
               if (type == "check") {
                    if (form[key] == null) {
                         form[key] = [];
                         form[key].push(option);
                    } else {
                         form[key].push(option);
                    }
               } else {
                    const index = form[key].indexOf(option);
                    if (index !== -1) {
                         form[key].splice(index, 1);
                    }
               }
          };

          const clearSignature = () => {
               signaturePad.value.clear();
          };

          const submitForm = async () => {
               const sign = signaturePad.value.toDataURL();
               form["signature"] = sign;

               await saveSignatureAPI(form)
                    .then((res) => {
                         if (res) {
                              successToast("Successfully saved!", "top-right");
                         }
                    })
                    .catch((err) => {
                         const { response } = err;
                         if (response.status === 422) {
                              form.errors.errors = response.data.errors;
                              errorToast(
                                   "Please ensure all fields are completed.",
                                   "top-right"
                              );
                         }
                    });
          };

          const clearFormError = (key) => {
               if (typeof form.errors.errors[key] !== "undefined") {
                    delete form.errors.errors[key];
               }
          };

          onMounted(async () => {
               signaturePad.value = new SignaturePad(
                    document.getElementById("signature-pad"),
                    {
                         backgroundColor: "rgba(255, 255, 255, 0)",
                         penColor: "rgb(0, 0, 0)",
                         minWidth: 1,
                         maxWidth: 1
                    }
               );
          });

          return {
               form,
               vip_form,
               clearSignature,
               submitForm,
               clearFormError,
               generateObject,
               markCheckbox
          };
     }
};
</script>
