<template>
     <div
          class="z-60 font-roboto tracking-ider font-bold text-sm xl:text-base 2xl:text-lg"
          :class="
               !onPlay || deviceType() != 'mobile'
                    ? 'nav-container fixed bg-moozi-1 top-0'
                    : 'absolute top-2 left-2'
          "
     >
          <!-- <div
               v-if="!onPlay || deviceType() != 'mobile'"
               class="header-snow"
          ></div> -->
          <nav
               class="w-full"
               :style="
                    !onPlay || deviceType() != 'mobile'
                         ? 'width: 100vw !important'
                         : ''
               "
               :class="
                    !onPlay || deviceType() != 'mobile'
                         ? 'bg-moozi-1 mt-[1px]'
                         : ''
               "
          >
               <div
                    class="h-full flex-auto header-bg w-full flex flex-wrap flex-row items-center justify-between gap-2"
                    :class="
                         !onPlay || deviceType() != 'mobile'
                              ? 'bg-moozi-1 mx-auto'
                              : ''
                    "
               >
                    <button
                         @click="setMenu(!menuAuth)"
                         class="flex-initial flex-wrap items-center p-2 xs:p-4 text-white hover:text-secondary-3 font-lg text-sm"
                         type="button"
                         :class="
                              !onPlay || deviceType() != 'mobile'
                                   ? 'justify-between mx-auto rounded-lg pl-3 xs:pl-5'
                                   : 'justify-start bg-black rounded-full bg-opacity-50'
                         "
                    >
                         <img
                              class="w-5 xs:w-7 h-5 xs:h-7"
                              :src="
                                   baseUrl.img +
                                   '/assets/img/header/burgermenu.svg'
                              "
                              alt=""
                         />
                    </button>
                    <div
                         v-if="!onPlay || deviceType() != 'mobile'"
                         class="flex-1 header-bg h-full w-full flex flex-wrap items-center justify-between pr-3 xs:!pr-5"
                    >
                         <span
                              @click="enableScroll(), setMenuOpen()"
                              class="h-full"
                         >
                              <a
                                   @click="onLobby"
                                   class="flex items-center space-x-3 rtl:space-x-reverse h-16 md:h-full cursor-pointer"
                              >
                                   <img
                                        class="w-16 xs:w-18 lg:w-20 -translate-y-1.5 lg:-translate-y-1"
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/logo.gif'
                                        "
                                        alt=""
                                   />
                              </a>
                         </span>
                         <div class="flex items-center space-x-6 relative">
                              <div
                                   class="hidden lg:flex items-center justify-between w-auto"
                                   id="navbar-cta"
                              >
                                   <div
                                        class="flex items-center p-0 mt-0 space-x-4 xl:space-x-8 2xl:space-x-12 flex-row md:mt-0 md:border-0"
                                   >
                                        <div>
                                             <a
                                                  class="flex items-center"
                                                  :id="
                                                       deviceType() == 'mobile'
                                                            ? ''
                                                            : 'notification-dewacrm'
                                                  "
                                                  @click="
                                                       deviceType() == 'mobile'
                                                            ? goToNotifications()
                                                            : ''
                                                  "
                                                  href="javascript:void(0)"
                                             >
                                                  <div
                                                       class="flex items-center"
                                                       :id="
                                                            deviceType() ==
                                                            'mobile'
                                                                 ? ''
                                                                 : 'notification-icon-dewacrm'
                                                       "
                                                  >
                                                       <img
                                                            class="cursor-pointer w-5"
                                                            :src="
                                                                 baseUrl.img +
                                                                 '/assets/img/header/bell.svg'
                                                            "
                                                            alt=""
                                                       />
                                                  </div>
                                             </a>
                                        </div>
                                   </div>
                              </div>
                              <div
                                   class="flex flex-row sm:space-x-2 lg:space-x-3"
                              >
                                   <!-- scale-100 hover:scale-105 -->
                                   <router-link
                                        :to="{ name: 'gold-coin-shop' }"
                                        class="h-10 sm:h-11 hidden md:flex flex-row items-center gap-1 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-2 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                                   >
                                        <img
                                             class="h-7"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/header/gc-sc.svg'
                                             "
                                             alt=""
                                        />
                                        <span
                                             class="cursor-pointer font-roboto text-sm font-extrabold"
                                             >Get Coins</span
                                        >
                                   </router-link>
                                   <div
                                        v-if="
                                             !onPlay || deviceType() != 'mobile'
                                        "
                                        class="h-10 sm:h-11 flex items-center md:relative tracking-widest p-1.5 bg-moozi-2 rounded-full !font-helveticaLight"
                                   >
                                        <div
                                             @click="setActiveCoinType('gold')"
                                             :class="
                                                  active_coin_type == 'gold'
                                                       ? 'bg-moozi-4 text-white'
                                                       : 'bg-transparent text-moozi-6'
                                             "
                                             class="h-full rounded-full coin-wrapper cursor-pointer"
                                             id="gold-coin-trigger"
                                        >
                                             <div
                                                  class="flex items-center justify-center tracking-wider"
                                             >
                                                  <img
                                                       @click="
                                                            reloadBalance(
                                                                 'gold'
                                                            )
                                                       "
                                                       class="cursor-pointer w-7 sm:w-8"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/GC.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <span
                                                       :class="
                                                            active_coin_type ==
                                                            'gold'
                                                                 ? ''
                                                                 : 'hidden md:block'
                                                       "
                                                       class="pl-2 pr-3 text-xs sm:text-sm font-bold"
                                                  >
                                                       {{
                                                            loadingGold
                                                                 ? ""
                                                                 : !onPlay ||
                                                                     active_coin_type !=
                                                                          "gold"
                                                                   ? money(
                                                                          gold_balance
                                                                     )
                                                                   : "In Use"
                                                       }}
                                                       <loading-small
                                                            :isLoading="
                                                                 loadingGold
                                                            "
                                                       />
                                                  </span>
                                             </div>
                                             <span class="text-left"></span>
                                             <Tooltip
                                                  :type="active_coin_type"
                                             />
                                        </div>
                                        <div
                                             @click="
                                                  setActiveCoinType('silver')
                                             "
                                             :class="
                                                  active_coin_type == 'silver'
                                                       ? 'bg-moozi-4 text-white'
                                                       : 'bg-transparent text-moozi-6'
                                             "
                                             class="mx-2 bg-background-2 rounded-full coin-wrapper cursor-pointer"
                                             id="silver-coin-trigger"
                                        >
                                             <div
                                                  class="flex items-center justify-center tracking-wider"
                                             >
                                                  <img
                                                       @click="
                                                            reloadBalance(
                                                                 'silver'
                                                            )
                                                       "
                                                       class="cursor-pointer w-7 sm:w-8"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/SC.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <span
                                                       :class="
                                                            active_coin_type ==
                                                            'silver'
                                                                 ? ''
                                                                 : 'hidden md:block'
                                                       "
                                                       class="pl-2 pr-3 text-xs sm:text-sm font-bold"
                                                  >
                                                       {{
                                                            loadingSilver
                                                                 ? ""
                                                                 : !onPlay ||
                                                                     active_coin_type !=
                                                                          "silver"
                                                                   ? money(
                                                                          silver_balance
                                                                     )
                                                                   : "In Use"
                                                       }}
                                                       <loading-small
                                                            :isLoading="
                                                                 loadingSilver
                                                            "
                                                       />
                                                  </span>
                                             </div>
                                             <Tooltip :type="'silver'" />
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </nav>
          <!-- drawer component -->
          <div
               v-if="menuAuth"
               @click="setMenu(false)"
               id="drawer-backdrop"
               class="fixed left-0 w-full lg:hidden"
          ></div>
          <div
               :style="[
                    !menuAuth ? 'left:-256px' : '',
                    !onPlay || deviceType() != 'mobile'
                         ? ''
                         : 'height: calc(100%);'
               ]"
               id="drawer-navigation"
               class="fixed left-0 z-60 overflow-hidden w-58 overflow-y-auto transform bg-moozi-2 text-shade-1 ease-in-out transition-all duration-300"
               tabindex="-1"
               aria-labelledby="drawer-navigation-label"
               :class="!onPlay || deviceType() != 'mobile' ? '' : 'top-0'"
          >
               <div
                    class="drawer-navigation-wrapper overflow-y-auto overflow-x-hidden h-full flex flex-col relative"
               >
                    <div class="bg-moozi-2 drop-shadow-xl relative">
                         <div class="px-4 md:px-5 pb-4">
                              <Tier v-if="isUser" />
                              <div
                                   class="flex justify-start items-center gap-2 px-1"
                              >
                                   <div
                                        class="flex justify-start flex-col items-left text-left overflow-hidden"
                                   >
                                        <p
                                             class="cursor-pointer text-sm leading-5 font-bold text-white text-nowrap text-ellipsis overflow-hidden"
                                        >
                                             {{ isUser?.username }}
                                        </p>
                                        <p
                                             class="mt-1 cursor-pointer text-xs leading-3 font-normal text-white text-nowrap text-ellipsis overflow-hidden"
                                        >
                                             {{ isUser?.email }}
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div
                         class="flex flex-col items-start h-full justify-between"
                    >
                         <div class="flex-1 w-full">
                              <ul
                                   class="left-navigation-container font-medium px-4 md:px-5 pt-3"
                              >
                                   <li>
                                        <router-link
                                             :to="{ name: 'gold-coin-shop' }"
                                             class="flex items-center py-2 rounded-lg group cursor-pointer get-coins"
                                        >
                                             <div
                                                  class="w-full h-10 sm:h-11 flex flex-row items-center justify-center gap-1 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-2 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                                             >
                                                  <img
                                                       class="h-5 sm:h-7"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/gc-sc.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <span
                                                       class="cursor-pointer font-roboto text-xs sm:text-sm font-extrabold"
                                                       >Get Coins</span
                                                  >
                                             </div>
                                        </router-link>
                                   </li>
                                   <li>
                                        <WelcomeButton
                                             v-if="isSelfExclusion == null"
                                        />
                                   </li>
                                   <li>
                                        <TimerMobile
                                             v-if="isSelfExclusion == null"
                                             :onClaimStatus="onClaimStatus"
                                        />
                                   </li>

                                   <li
                                        v-for="(
                                             item, key
                                        ) in active_daily_bonus"
                                        :key="key"
                                   >
                                        <a
                                             @click="
                                                  openEventDailyBonus(
                                                       item.type
                                                  ),
                                                       setMenuOpen()
                                             "
                                             class="flex items-center py-2 rounded-lg group cursor-pointer get-coins"
                                        >
                                             <div
                                                  class="bg-transparent w-full h-10 sm:h-11 flex flex-row items-center justify-center gap-1 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                                             >
                                                  <img
                                                       class="w-full h-full"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/bonus/' +
                                                            item.type +
                                                            '/button-daily-bonus.png'
                                                       "
                                                       alt=""
                                                  />
                                             </div>
                                        </a>
                                   </li>
                                   <!-- <li v-if="xmasBonusStatus">
                                        <a
                                             @click="openXmas(), setMenuOpen()"
                                             class="flex items-center py-2 rounded-lg group cursor-pointer get-coins"
                                        >
                                             <div
                                                  class="bg-red-40 w-full h-10 sm:h-11 flex flex-row items-center justify-center gap-1 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                                             >
                                                  <img
                                                       class="w-full h-full"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/bonus/xmas/button-daily-bonus.png'
                                                       "
                                                       alt=""
                                                  />
                                             </div>
                                        </a>
                                   </li>
                                   <li v-if="newYearBonusStatus">
                                        <a
                                             @click="
                                                  openNewYear(), setMenuOpen()
                                             "
                                             class="flex items-center py-2 rounded-lg group cursor-pointer get-coins"
                                        >
                                             <div
                                                  class="bg-violet-10 w-full h-10 sm:h-11 flex flex-row items-center justify-center gap-1 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                                             >
                                                  <img
                                                       class="w-full h-full"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/bonus/newyear/button-daily-bonus.png'
                                                       "
                                                       alt=""
                                                  />
                                             </div>
                                        </a>
                                   </li> -->
                                   <li v-if="deviceType() == 'mobile'">
                                        <div
                                             class="flex items-center justify-center py-2 rounded-lg group cursor-pointer"
                                        >
                                             <div
                                                  id="chat-button-mobile"
                                                  data-id="TPwRaUxqEJF"
                                                  class="w-full h-10 sm:h-11 flex flex-row items-center justify-center gap-3 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-2 bg-gradient-to-b from-yellow-10 to-yellow-20 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                                             >
                                                  <svg
                                                       v-if="
                                                            livechatCheck
                                                                 ? ''
                                                                 : 'hidden'
                                                       "
                                                       aria-hidden="true"
                                                       class="w-8 h-8 text-primal-black animate-spin fill-primal-yellow"
                                                       viewBox="0 0 100 101"
                                                       fill="none"
                                                       xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                       <path
                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                            fill="currentColor"
                                                       ></path>
                                                       <path
                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                            fill="currentFill"
                                                       ></path>
                                                  </svg>
                                                  <img
                                                       v-if="
                                                            livechatCheck
                                                                 ? 'hidden'
                                                                 : ''
                                                       "
                                                       src="/assets/img/chat_cow.svg"
                                                       class="w-12 flex-initial ml-2"
                                                  />
                                                  <span
                                                       v-if="
                                                            livechatCheck
                                                                 ? 'hidden'
                                                                 : ''
                                                       "
                                                       class="flex-1 cursor-pointer font-roboto text-xs sm:text-sm font-extrabold"
                                                       >Online Chat</span
                                                  >
                                             </div>
                                        </div>
                                   </li>
                                   <li @click="enableScroll(), setMenuOpen()">
                                        <a
                                             @click="onLobby"
                                             class="flex items-center py-2 rounded-lg group cursor-pointer"
                                             :class="
                                                  isUrl('lobby')
                                                       ? 'active-left-navigation'
                                                       : ''
                                             "
                                        >
                                             <div
                                                  class="h-7 sm:h-8 w-7 sm:w-8 content-center"
                                             >
                                                  <img
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/games.svg'
                                                       "
                                                  />
                                             </div>
                                             <span
                                                  class="ms-3 font-bold text-sm sm:text-base"
                                                  >Lobby</span
                                             >
                                        </a>
                                   </li>
                                   <li @click="enableScroll(), setMenuOpen()">
                                        <router-link
                                             :to="{ name: 'promotion' }"
                                             class="flex items-center py-2 rounded-lg group"
                                             :class="
                                                  isUrl('promotion')
                                                       ? 'active-left-navigation'
                                                       : ''
                                             "
                                        >
                                             <div
                                                  class="h-7 sm:h-8 w-7 sm:w-8 content-center"
                                             >
                                                  <img
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/promotion.svg'
                                                       "
                                                  />
                                             </div>
                                             <span
                                                  class="ms-3 font-bold text-sm sm:text-base"
                                                  >Promotions</span
                                             >
                                        </router-link>
                                   </li>
                                   <li>
                                        <router-link
                                             :to="{ name: 'redeem' }"
                                             class="flex items-center py-2 rounded-lg group cursor-pointer"
                                             :class="
                                                  isUrl('redeem')
                                                       ? 'active-left-navigation'
                                                       : ''
                                             "
                                        >
                                             <div
                                                  class="h-7 sm:h-8 w-7 sm:w-8 content-center"
                                             >
                                                  <img
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/redeem.svg'
                                                       "
                                                  />
                                             </div>
                                             <span
                                                  class="ms-3 font-bold text-sm sm:text-base"
                                                  >Redeem</span
                                             >
                                        </router-link>
                                   </li>
                                   <li @click="enableScroll(), setMenuOpen()">
                                        <router-link
                                             :to="{ name: 'support' }"
                                             class="flex items-center py-2 rounded-lg group"
                                             :class="
                                                  isUrl('support')
                                                       ? 'active-left-navigation'
                                                       : ''
                                             "
                                        >
                                             <div
                                                  class="h-7 sm:h-8 w-7 sm:w-8 content-center"
                                             >
                                                  <img
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/support.svg'
                                                       "
                                                  />
                                             </div>
                                             <span
                                                  class="ms-3 font-bold text-sm sm:text-base"
                                                  >Support</span
                                             >
                                        </router-link>
                                   </li>
                                   <li>
                                        <a
                                             @click="
                                                  openMyAccount(), setMenuOpen()
                                             "
                                             class="flex items-center py-2 rounded-lg group cursor-pointer"
                                        >
                                             <div
                                                  class="h-7 sm:h-8 w-7 sm:w-8 content-center"
                                             >
                                                  <img
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/account.svg'
                                                       "
                                                  />
                                             </div>
                                             <span
                                                  class="ms-3 font-bold text-sm sm:text-base"
                                                  >My Account</span
                                             >
                                        </a>
                                   </li>
                                   <li>
                                        <a
                                             @click="
                                                  goToNotifications(),
                                                       setMenuOpen()
                                             "
                                             class="flex items-center py-2 rounded-lg group cursor-pointer"
                                        >
                                             <div
                                                  class="h-7 sm:h-8 w-7 sm:w-8 content-center"
                                             >
                                                  <img
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/bell.svg'
                                                       "
                                                  />
                                             </div>
                                             <span
                                                  class="ms-3 font-bold text-sm sm:text-base"
                                                  >Notification</span
                                             >
                                        </a>
                                   </li>
                              </ul>
                         </div>
                         <div
                              class="mt-10 mb-10 w-full text-white cursor-pointer transform ease-in-out transition-all duration-300"
                         >
                              <div
                                   class="w-full flex flex-wrap items-center justify-center"
                              >
                                   <div
                                        @click="logout(), setMenuOpen()"
                                        class="text-center rounded-full cursor-pointer"
                                   >
                                        <span
                                             class="cursor-pointer text-moozi-6 text-sm sm:text-base"
                                             >Log Out</span
                                        >
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <div
               @click="setMenu(!menuAuth)"
               v-if="menuAuth && onPlay && deviceType() == 'mobile'"
               class="fixed inset-0 h-screen"
               style="background: rgba(0, 0, 0, 0.75)"
          ></div>

          <Kyc />
          <DailyBonus
               v-if="isSelfExclusion == null"
               :isClaimed="isClaimed"
          />
          <SocialMediaBonus />
          <WelcomeBonus />
          <BdayBonus />
          <idVerification />
          <!-- <Timer
               v-if="
                    !onPlay &&
                    !(kycNotAllowed == null ? [] : kycNotAllowed).includes(
                         'daily_bonus'
                    )
               "
               :onClaimStatus="onClaimStatus"
          /> -->
          <Terms />
          <FreeGCTopUp />
          <SessionExpired />
          <Reminder />
          <VerifyEmail />
          <GetCoins
               v-if="
                    !onPlay &&
                    route.path.replaceAll('/', '') != 'gold-coin-shop'
               "
          />
          <TierModal />
          <!-- <XmasModal /> -->
          <!-- <NewYearModal /> -->
          <EvantDailyBonus
               v-for="(item, key) in active_daily_bonus"
               :key="key"
               :type="item.type"
          />
          <ProcessSuccess />
          <ProcessFailed />
     </div>
</template>

<script>
import {
     ref,
     computed,
     watch,
     onMounted,
     reactive,
     onBeforeUnmount
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useFormatter } from "composables/use-formatter.js";
import eventBus from "plugins/event.js";
import Kyc from "components/modals/kyc.vue";
import DailyBonus from "components/modals/bonus/daily.vue";
import SocialMediaBonus from "components/modals/socialMediaBonus.vue";
import WelcomeBonus from "components/modals/bonus/welcome.vue";
// import Timer from "components/timer/index.vue";
import { authStore } from "store/auth.js";
import LoadingSmall from "@/components/loading/loadingSmall.vue";
import idVerification from "components/modals/idVerification.vue";
import { useIOSocketInit } from "composables/use-socket-init.js";
import Terms from "components/modals/terms.vue";
import FreeGCTopUp from "components/modals/freeGCTopUp.vue";
import SessionExpired from "components/modals/sessionExpired.vue";
import Reminder from "components/modals/reminder.vue";
import VerifyEmail from "components/modals/verifyEmail.vue";
import { baseUrl } from "config/services.js";
import Tooltip from "./component/tooltip.vue";
import TimerMobile from "./component/timer.vue";
import WelcomeButton from "./component/welcome.vue";
// import TimerChrismassMobile from "./component/timer-christmas.vue";
import { utilitiesStore } from "store/utilities.js";
import device from "middleware/device.js";
// import { useKYC } from "composables/use-kyc.js";
import _ from "underscore";
import { gameLauncher as gameLauncherAPI } from "@/api/seamless";
import { useToaster } from "composables/use-toaster.js";
import GetCoins from "components/floatingBtn/get-coins.vue";
import { bonusStore } from "store/bonus.js";
import Tier from "components/tier/index.vue";
import TierModal from "components/modals/tier.vue";
import BdayBonus from "components/modals/bonus/bday.vue";
import EvantDailyBonus from "components/modals/bonus/event-daily-bonus.vue";
// import XmasModal from "components/modals/bonus/xmas.vue";
// import NewYearModal from "components/modals/bonus/newyear.vue";
import { useKYC } from "composables/use-kyc.js";
import ProcessSuccess from "components/modals/processSuccess.vue";
import ProcessFailed from "components/modals/processFailed.vue";

export default {
     components: {
          Kyc,
          DailyBonus,
          SocialMediaBonus,
          WelcomeBonus,
          LoadingSmall,
          idVerification,
          Terms,
          FreeGCTopUp,
          SessionExpired,
          Reminder,
          Tooltip,
          VerifyEmail,
          GetCoins,
          TimerMobile,
          Tier,
          TierModal,
          BdayBonus,
          EvantDailyBonus,
          // XmasModal,
          // NewYearModal,
          ProcessSuccess,
          ProcessFailed,
          WelcomeButton
     },
     props: ["onPlay"],
     setup(props) {
          const { destroySocketIO } = useIOSocketInit();
          const { validateEveryAction } = useKYC();

          const useUtilitiesStore = utilitiesStore();

          const route = useRoute();
          const router = useRouter();

          const useAuthStore = authStore();
          const useBonusStore = bonusStore();

          const mountFlag = ref(false);

          const loadingGold = ref(false);
          const loadingSilver = ref(false);

          const isClaimed = ref(false);

          const isInitBday = ref(false);

          const { money } = useFormatter();
          const { deviceType } = device();
          const { errorToast } = useToaster();

          const livechatCheck = ref(null);
          const livechatEyeCatcherCheck = ref(null);

          const livechatInterval = ref(
               setInterval(() => {
                    livechatCheck.value = document.getElementById(
                         "chat-widget-container"
                    );

                    if (livechatCheck.value != null) {
                         if (deviceType() == "mobile" || props.onPlay) {
                              document
                                   .getElementById("chat-widget-container")
                                   ?.classList?.add("hidden");
                              document
                                   .querySelector(".livechat_button")
                                   ?.classList?.add("hidden");
                         }
                         clearInterval(livechatInterval.value);
                    }
               }, 1000)
          );

          const livechatEyeCatcherInterval = ref(
               setInterval(() => {
                    livechatEyeCatcherCheck.value = document.getElementById(
                         "livechat-eye-catcher"
                    );
                    if (livechatEyeCatcherCheck.value != null) {
                         if (deviceType() == "mobile" || props.onPlay) {
                              document
                                   .getElementById("livechat-eye-catcher")
                                   ?.classList?.add("hidden");
                         }
                         clearInterval(livechatEyeCatcherInterval.value);
                    }
               }, 1000)
          );

          const apiParams = reactive({
               type: ""
          });

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const isSelfExclusion = computed(() => {
               return useAuthStore.isSelfExclusion;
          });

          const active_daily_bonus = computed(() => {
               return useBonusStore.active_daily_bonus;
          });

          // const xmasBonusStatus = computed(() => {
          //      return useBonusStore.dailyBonus?.["daily-christmas"]
          //           ? true
          //           : false;
          // });

          // const newYearBonusStatus = computed(() => {
          //      return useBonusStore.dailyBonus?.["daily-newyear"]
          //           ? true
          //           : false;
          // });

          const active_coin_type = computed(() => {
               return useAuthStore.active_coin_type || route.params.type;
          });

          const state = computed(() => {
               return useUtilitiesStore.state;
          });

          const menuAuth = computed(() => {
               return useUtilitiesStore.menuAuth;
          });

          const kycNotAllowed = computed(() => {
               return useAuthStore.kycNotAllowed;
          });

          const reloadBalance = async (val1) => {
               if (val1 == "gold") {
                    loadingGold.value = true;
                    apiParams.type = "gold_coins";
                    try {
                         await useAuthStore.reloadBalance(apiParams);
                         loadingGold.value = false;
                    } catch (error) {
                         console.error("Error gatting balance:", error);
                    }
               } else {
                    loadingSilver.value = true;
                    apiParams.type = "silver_coins";
                    try {
                         await useAuthStore.reloadBalance(apiParams);
                         loadingSilver.value = false;
                    } catch (error) {
                         console.error("Error gatting balance:", error);
                    }
               }
          };

          const gold_balance = computed(() => {
               return useAuthStore.gold_balance;
          });

          const silver_balance = computed(() => {
               return (
                    parseFloat(useAuthStore.silver_balance) +
                    parseFloat(useAuthStore.silver_balance_redeem)
               );
          });

          const isUrl = (...urls) => {
               let currentUrl = route.path.substr(1);
               if (urls[0] === "") {
                    return currentUrl === "";
               }
               return urls.filter((url) => currentUrl.startsWith(url)).length;
          };

          // const openDailyBonus = () => {
          //      eventBus.emit("open:dailybonus");
          //      router.replace({ query: { modal: "dailybonus" } });
          // };

          // const openSocialMediaBonus = () => {
          //      eventBus.emit("open:socialmediabonus");
          //      router.replace({ query: { modal: "socialmediabonus" } });
          // };

          const openMyAccount = () => {
               enableScroll();
               router.push({
                    path: "/account",
                    query: { tab: "account-details" }
               });
          };

          // const openWelcomeBonus = () => {
          //      eventBus.emit("open:welcomebonus");
          //      router.replace({ query: { modal: "welcomebonus" } });
          // };

          const onClaimStatus = (data) => {
               isClaimed.value = data;
          };

          const logout = () => {
               useAuthStore.logout().then(() => {
                    // router.push({ path: "/signin" });
                    window.location.href = "/signin";
               });
          };

          const setMenu = (data) => {
               useUtilitiesStore.setMenuAuth(data);
          };

          const myEventHandler = () => {
               if (deviceType() == "mobile") {
                    setMenu(false);
               } else {
                    if (window.innerWidth < 1024) {
                         setMenu(false);
                         return;
                    }
                    setMenu(true);
               }
          };

          const setMenuOpen = () => {
               if (deviceType() == "mobile") {
                    setMenu(false);
               }
          };

          const goToNotifications = () => {
               enableScroll();
               window.location.href = "notification";
          };

          const navigateLink = (path) => {
               enableScroll();
               switch (path) {
                    case "home":
                         router.push({ path: "/" });
                         break;
                    case "promotion":
                         router.push({ path: "/promotion" });
                         break;
                    case "support":
                         router.push({ path: "/support" });
                         break;
                    case "redeem":
                         router.push({ path: "/redeem" });
                         // validateNavigation("from_redeem");
                         break;
                    default:
                         break;
               }
          };

          const enableScroll = () => {
               useUtilitiesStore.enableScroll();
          };

          const hoverCoin = (id, otherTrigger) => {
               const element = document.getElementById(id);
               const tempTrigger = document.getElementById(otherTrigger);

               if (deviceType() == "mobile") {
                    element.onclick = function () {
                         element.classList.remove("delay");

                         if (tempTrigger.classList.contains("show")) {
                              tempTrigger.classList.add("delay");
                              tempTrigger.classList.remove("show");
                         } else {
                              element.classList.add("show");
                         }
                    };
               } else {
                    element.addEventListener("mouseover", () => {
                         element.classList.remove("delay");

                         if (tempTrigger.classList.contains("show")) {
                              tempTrigger.classList.add("delay");
                              tempTrigger.classList.remove("show");
                         } else {
                              element.classList.add("show");
                         }
                    });

                    element.addEventListener("mouseleave", function () {
                         element.classList.remove("show");
                    });
               }
          };

          const setActiveCoinType = (type) => {
               if (active_coin_type.value != type) {
                    useAuthStore.setActiveCoinType(type);
                    if (props.onPlay) {
                         onLaunch(type);
                    }
               }
          };

          onBeforeUnmount(() => {
               destroySocketIO();
          });

          const onLaunch = async (type) => {
               if (isUser.value) {
                    // if (validateNavigation() == true) {
                    //      return;
                    // }

                    if (active_coin_type.value != "gold") {
                         reloadBalance("gold");
                    } else {
                         reloadBalance("silver");
                    }

                    await gameLauncherAPI({
                         game_slug: route.params.slug,
                         type: type
                    })
                         .then(async (res) => {
                              if (res.status) {
                                   if (res) {
                                        if (res.launch_action == "redirect") {
                                             window.location.href = res.url;
                                             return;
                                        }
                                   }

                                   if (
                                        /iPad|iPhone|iPod/.test(
                                             navigator.userAgent
                                        )
                                   ) {
                                        // alert("This is an iOS device.");
                                        setTimeout(async () => {
                                             //  window.open(res.url, "_blank");
                                             await useAuthStore.setActiveGameUrl(
                                                  res.url
                                             );
                                             router.push({
                                                  name: "play",
                                                  params: {
                                                       slug: route.params.slug,
                                                       id: route.params.id,
                                                       launchcode:
                                                            res.launch_code,
                                                       type: type
                                                  }
                                             });
                                        });
                                   } else {
                                        await useAuthStore.setActiveGameUrl(
                                             res.url
                                        );
                                        router.push({
                                             name: "play",
                                             params: {
                                                  slug: route.params.slug,
                                                  id: route.params.id,
                                                  launchcode: res.launch_code,
                                                  type: type
                                             }
                                        });
                                        // window.open(res.url, "_blank");
                                   }
                              }
                         })
                         .catch(() => {
                              errorToast("Something went wrong!", "top-right");
                         });
               } else {
                    router.push({ name: "login" });
               }
          };

          const onLobby = () => {
               if (route.name == "lobby") {
                    eventBus.emit("back_to_lobby");
                    eventBus.emit("clear_search_lobby");
               } else {
                    console.log("force");
                    router.push({ name: "lobby", force: true });
               }
          };

          const openEventDailyBonus = (type) => {
               if (validateEveryAction("bonus") == true) {
                    return;
               }

               eventBus.emit("open:event-" + type);
          };

          // const openXmas = () => {
          //      if (validateEveryAction("bonus") == true) {
          //           return;
          //      }

          //      eventBus.emit("open:xmas_modal");
          // };

          // const openNewYear = () => {
          //      if (validateEveryAction("bonus") == true) {
          //           return;
          //      }

          //      eventBus.emit("open:newyear_modal");
          // };

          onMounted(async () => {
               hoverCoin("gold-coin-trigger", "silver-coin-trigger");
               hoverCoin("silver-coin-trigger", "gold-coin-trigger");
               await useBonusStore.getActiveDailyBonus();
               myEventHandler();
               window.addEventListener("resize", function () {
                    myEventHandler();
               });
          });

          watch(isUser, async (newVal) => {
               if (newVal?.meta) {
                    if (route.name == "lobby") {
                         let filter = _.filter(newVal.meta, function (params) {
                              return params["meta_key"] == "welcome_bonus";
                         });

                         if (filter.length == 0) {
                              setTimeout(async () => {
                                   await useBonusStore.getWelcome();
                              }, 0);
                         }

                         if (
                              newVal.birthday != null &&
                              newVal.birthday != "" &&
                              !isInitBday.value
                         ) {
                              await useBonusStore.getBday(newVal.birthday);
                              isInitBday.value = true;
                         }
                    }
               }

               if (newVal && !mountFlag.value) {
                    if (route.query.modal == "idverification") {
                         eventBus.emit("open:idverification");
                    }
                    mountFlag.value = true;
               }
          });

          watch(livechatInterval, async () => {
               livechatCheck.value = document.getElementById(
                    "chat-widget-container"
               );
          });

          watch(livechatEyeCatcherInterval, async () => {
               livechatEyeCatcherCheck.value = document.getElementById(
                    "livechat-eye-catcher"
               );
          });

          watch([isUser, state], async (newVal) => {
               if (newVal[0] && newVal[1]?.length != 0) {
                    useAuthStore.setLiveChatAttributes("state", newVal);
               }
          });

          return {
               isUser,
               isUrl,
               // validateNavigation,
               // openDailyBonus,
               // openSocialMediaBonus,
               openMyAccount,
               // openWelcomeBonus,
               logout,
               gold_balance,
               silver_balance,
               reloadBalance,
               loadingGold,
               loadingSilver,
               money,
               goToNotifications,
               onClaimStatus,
               isClaimed,
               navigateLink,
               baseUrl,
               enableScroll,
               active_coin_type,
               setActiveCoinType,
               setMenuOpen,
               deviceType,
               menuAuth,
               setMenu,
               route,
               kycNotAllowed,
               livechatCheck,
               onLobby,
               isSelfExclusion,
               active_daily_bonus,
               openEventDailyBonus
               // openXmas,
               // openNewYear,
               // xmasBonusStatus,
               // newYearBonusStatus
          };
     }
};
</script>
