<template>
     <span
          class="icon-uncheck"
          :class="className"
          ><span
               class="path1"
               :class="className"
          ></span
          ><span
               class="path2"
               :class="className"
          ></span
          ><span
               class="path3"
               :class="className"
          ></span
     ></span>
</template>
<script>
export default {
     props: ["className"]
};
</script>
