<template>
     <!-- Main modal -->
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               :class="isPaymentCheckout ? '' : '!hidden z-0'"
               id="payment-checkout-modal"
               tabindex="-1"
               class="overflow-y-hidden overflow-x-hidden fixed z-80 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center m-auto flex justify-center items-center"
          >
               <div
                    class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-3xl max-h-full tracking-wider m-auto"
               >
                    <!-- Modal content -->
                    <div
                         class="relative bg-white rounded-lg shadow font-roboto"
                    >
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div
                              v-if="coin"
                              class="bg-gradient-page px-5 pt-5 pb-8 flex flex-col w-full h-max text-white rounded-t-lg"
                         >
                              <div class="flex flex-row justify-between pr-5">
                                   <p
                                        class="font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                   >
                                        {{
                                             coin.action == "package"
                                                  ? coin.type
                                                  : "Custom Amount"
                                        }}
                                   </p>
                                   <p
                                        class="font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl"
                                   >
                                        Total
                                   </p>
                              </div>
                              <div class="flex flex-row justify-between pr-5">
                                   <p
                                        class="font-normal text-sm md:text-base lg:text-sm xl:text-base"
                                   >
                                        {{ money(coin.gc) }} GC + FREE
                                        {{ money(coin.sc) }} SC
                                   </p>
                                   <p
                                        class="font-bold text-md sm:text-xl md:text-2xl lg:text-xl xl:text-2xl"
                                   >
                                        ${{ money(coin.total) }}
                                   </p>
                              </div>
                         </div>
                         <div
                              class="px-5 py-5 flex flex-col w-full overflow-y-auto"
                              style="height: 60vh; max-height: 60vh"
                         >
                              <div>
                                   <p
                                        class="font-bold text-black text-sm md:text-base lg:text-sm xl:text-base mb-2"
                                   >
                                        Payment methods
                                   </p>
                                   <div class="flex flex-col sm:flex-row gap-2">
                                        <div
                                             v-if="
                                                  isActionAccessible(
                                                       'dp_bank_button'
                                                  )
                                             "
                                             class="flex flex-row gap-2 rounded-full w-full sm:w-max md:w-4/12 items-center justify-center px-3 py-2 cursor-pointer"
                                             :class="
                                                  paymentMethod == 0
                                                       ? 'bg-background-7 !text-white'
                                                       : 'bg-transparent !text-black'
                                             "
                                             @click="paymentMethod = 0"
                                        >
                                             <svg
                                                  width="19"
                                                  height="19"
                                                  viewBox="0 0 19 19"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                             >
                                                  <g
                                                       clip-path="url(#clip0_4949_27832)"
                                                  >
                                                       <path
                                                            d="M9.06422 0.682465L1.27125 4.02231C0.784194 4.23105 0.512831 4.7529 0.620681 5.26779C0.72853 5.78268 1.1808 6.15842 1.70961 6.15842V6.43674C1.70961 6.89944 2.08186 7.2717 2.54457 7.2717H16.4606C16.9233 7.2717 17.2955 6.89944 17.2955 6.43674V6.15842C17.8244 6.15842 18.2801 5.78616 18.3845 5.26779C18.4888 4.74942 18.2175 4.22757 17.7339 4.02231L9.94093 0.682465C9.66261 0.564178 9.34254 0.564178 9.06422 0.682465ZM5.04945 8.38498H2.82289V15.2143C2.80202 15.2247 2.78114 15.2386 2.76027 15.2525L1.09035 16.3658C0.683303 16.6372 0.498915 17.1451 0.641555 17.6148C0.784194 18.0844 1.21907 18.4045 1.70961 18.4045H17.2955C17.7861 18.4045 18.2175 18.0844 18.3601 17.6148C18.5028 17.1451 18.3219 16.6372 17.9113 16.3658L16.2414 15.2525C16.2205 15.2386 16.1997 15.2282 16.1788 15.2143L16.1823 8.38498H13.9557V15.0647H12.5641V8.38498H10.3375V15.0647H8.66762V8.38498H6.44105V15.0647H5.04945V8.38498ZM9.50258 2.81857C9.79784 2.81857 10.081 2.93586 10.2898 3.14465C10.4986 3.35343 10.6159 3.63659 10.6159 3.93185C10.6159 4.22711 10.4986 4.51028 10.2898 4.71906C10.081 4.92784 9.79784 5.04514 9.50258 5.04514C9.20732 5.04514 8.92415 4.92784 8.71537 4.71906C8.50659 4.51028 8.3893 4.22711 8.3893 3.93185C8.3893 3.63659 8.50659 3.35343 8.71537 3.14465C8.92415 2.93586 9.20732 2.81857 9.50258 2.81857Z"
                                                            :fill="
                                                                 paymentMethod ==
                                                                 0
                                                                      ? '#FFF'
                                                                      : '#000'
                                                            "
                                                       />
                                                  </g>
                                                  <defs>
                                                       <clipPath
                                                            id="clip0_4949_27832"
                                                       >
                                                            <rect
                                                                 width="19"
                                                                 height="19"
                                                                 :fill="
                                                                      paymentMethod ==
                                                                      0
                                                                           ? '#FFF'
                                                                           : '#000'
                                                                 "
                                                            />
                                                       </clipPath>
                                                  </defs>
                                             </svg>
                                             <div
                                                  class="font-medium text-sm md:text-base lg:text-sm xl:text-base"
                                             >
                                                  Pay by bank
                                             </div>
                                        </div>
                                        <div
                                             v-if="
                                                  isActionAccessible(
                                                       'dp_card_button'
                                                  )
                                             "
                                             class="flex flex-row gap-2 rounded-full w-full sm:w-max md:w-4/12 items-center justify-center px-3 py-2 cursor-pointer"
                                             :class="
                                                  paymentMethod == 1
                                                       ? 'bg-background-7 !text-white'
                                                       : 'bg-transparent !text-black'
                                             "
                                             @click="paymentMethod = 1"
                                        >
                                             <svg
                                                  width="20"
                                                  height="19"
                                                  viewBox="0 0 20 19"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                             >
                                                  <path
                                                       d="M3.07292 2.375C1.98128 2.375 1.09375 3.28789 1.09375 4.41071V5.42857H18.9063V4.41071C18.9063 3.28789 18.0187 2.375 16.9271 2.375H3.07292ZM18.9063 8.48214H1.09375V14.5893C1.09375 15.7121 1.98128 16.625 3.07292 16.625H16.9271C18.0187 16.625 18.9063 15.7121 18.9063 14.5893V8.48214ZM4.55729 12.5536H6.53646C6.80859 12.5536 7.03125 12.7826 7.03125 13.0625C7.03125 13.3424 6.80859 13.5714 6.53646 13.5714H4.55729C4.28516 13.5714 4.0625 13.3424 4.0625 13.0625C4.0625 12.7826 4.28516 12.5536 4.55729 12.5536ZM8.02083 13.0625C8.02083 12.7826 8.24349 12.5536 8.51563 12.5536H12.474C12.7461 12.5536 12.9688 12.7826 12.9688 13.0625C12.9688 13.3424 12.7461 13.5714 12.474 13.5714H8.51563C8.24349 13.5714 8.02083 13.3424 8.02083 13.0625Z"
                                                       :fill="
                                                            paymentMethod == 1
                                                                 ? '#FFF'
                                                                 : '#000'
                                                       "
                                                  />
                                             </svg>
                                             <div
                                                  class="font-medium text-sm md:text-base lg:text-sm xl:text-base"
                                             >
                                                  Pay by card
                                             </div>
                                        </div>
                                        <!-- hidden  -->
                                        <div
                                             v-if="
                                                  isActionAccessible(
                                                       'applepay_button'
                                                  )
                                             "
                                             id="ap-button-tab"
                                             class="w-full sm:w-max md:w-4/12"
                                             :class="
                                                  isAndroid() ? 'hidden' : ''
                                             "
                                        >
                                             <div
                                                  class="flex flex-row gap-1 rounded-full w-full items-center justify-center px-3 py-2 cursor-pointer"
                                                  :class="
                                                       paymentMethod == 2
                                                            ? 'bg-background-7 !text-white'
                                                            : 'bg-transparent !text-black'
                                                  "
                                                  @click="paymentMethod = 2"
                                             >
                                                  <svg
                                                       width="19"
                                                       height="19"
                                                       viewBox="0 0 19 19"
                                                       fill="none"
                                                       xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                       <path
                                                            d="M11.9375 4.74908L12.1268 4.75858C13.2588 4.83537 14.8453 5.56054 15.6956 6.80583C15.7578 6.89699 15.8003 7.00015 15.8203 7.10869C15.8403 7.21723 15.8374 7.32875 15.8117 7.4361C15.7861 7.54345 15.7383 7.64425 15.6714 7.73203C15.6045 7.81982 15.52 7.89264 15.4233 7.94583C15.3837 7.968 15.1288 8.07091 15.0298 8.11762C14.8282 8.21171 14.6367 8.32603 14.4582 8.45883C13.9721 8.82379 13.7077 9.25762 13.7125 9.79991C13.7204 10.5005 13.9808 10.951 14.4305 11.2613C14.5952 11.3745 14.7757 11.4616 14.9577 11.5226C15.0575 11.5566 15.295 11.6144 15.3092 11.6192C15.4628 11.6743 15.5956 11.7756 15.6894 11.909C15.7832 12.0425 15.8335 12.2018 15.8333 12.3649C15.8333 14.4399 13.4267 17.4165 11.6486 17.4165C11.0232 17.4165 10.6416 17.3445 10.0787 17.1672L10.0011 17.1426C9.63458 17.027 9.44538 16.9906 9.10417 16.9906C8.6925 16.9906 8.42096 17.0381 7.90242 17.1782L7.74646 17.2202C7.29125 17.3413 6.98329 17.3991 6.55896 17.4165C4.38267 17.4165 2.375 13.3846 2.375 10.2345C2.375 7.17079 3.78892 4.7562 6.55975 4.7562C6.79488 4.7562 7.03317 4.79183 7.27937 4.85754C7.59842 4.94225 7.89213 5.06337 8.30538 5.2597C8.92842 5.55579 9.05588 5.60804 9.10417 5.60804H9.11683C9.14058 5.60566 9.21817 5.57083 9.95283 5.24625C10.7928 4.87654 11.4285 4.70395 12.1268 4.75858L11.9368 4.74908H11.9375Z"
                                                            :fill="
                                                                 paymentMethod ==
                                                                 2
                                                                      ? '#FFF'
                                                                      : '#000'
                                                            "
                                                       />
                                                       <path
                                                            d="M11.0833 0.791504C11.2933 0.791504 11.4947 0.874911 11.6431 1.02338C11.7916 1.17184 11.875 1.37321 11.875 1.58317C11.875 2.21306 11.6248 2.81715 11.1794 3.26255C10.734 3.70795 10.1299 3.95817 9.5 3.95817C9.29004 3.95817 9.08867 3.87476 8.94021 3.7263C8.79174 3.57783 8.70833 3.37647 8.70833 3.1665C8.70833 2.53661 8.95856 1.93252 9.40395 1.48713C9.84935 1.04173 10.4534 0.791504 11.0833 0.791504Z"
                                                            :fill="
                                                                 paymentMethod ==
                                                                 2
                                                                      ? '#FFF'
                                                                      : '#000'
                                                            "
                                                       />
                                                  </svg>

                                                  <div
                                                       class="font-medium text-sm md:text-base lg:text-sm xl:text-base"
                                                  >
                                                       Pay
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div
                                   class="h-full"
                                   :class="
                                        paymentMethod == 0 &&
                                        isActionAccessible('dp_bank_button')
                                             ? ''
                                             : 'hidden'
                                   "
                              >
                                   <OnlineBanking
                                        :coins="coin ?? {}"
                                        :setPaymentSuccessCoins="
                                             setPaymentSuccessCoins
                                        "
                                        :setLoading="setLoadingCheckout"
                                        :closeModal="closeModal"
                                        :gtagSelected="gtagSelected"
                                   />
                              </div>
                              <div
                                   class="h-full"
                                   :class="
                                        paymentMethod == 1 &&
                                        isActionAccessible('dp_card_button')
                                             ? ''
                                             : 'hidden'
                                   "
                              >
                                   <CreditCard
                                        :wp_response="wp_response"
                                        :payframeClientLoaded="
                                             payframeClientLoaded
                                        "
                                        :confirmationFunc="confirmationFunc"
                                        :coins="coin ?? {}"
                                        :setPaymentSuccessCoins="
                                             setPaymentSuccessCoins
                                        "
                                        :setLoading="setLoadingCheckout"
                                        :closeModal="closeModal"
                                        :gtagSelected="gtagSelected"
                                        :initWorldPay="initWorldPay"
                                        :isAddNewCard="isAddNewCard"
                                        :setAddNewCard="setAddNewCard"
                                        :setConfirmationFunc="
                                             setConfirmationFunc
                                        "
                                   />
                              </div>
                              <div
                                   :class="paymentMethod == 2 ? '' : 'hidden'"
                                   class="w-full h-full"
                              >
                                   <!-- class="hidden" -->
                                   <div
                                        id="ap-button-panel"
                                        :class="isAndroid() ? 'hidden' : ''"
                                   >
                                        <ApplePay
                                             v-if="
                                                  isActionAccessible(
                                                       'applepay_button'
                                                  )
                                             "
                                             :coins="coin"
                                             :setLoading="setLoadingCheckout"
                                             :setPaymentSuccessCoins="
                                                  setPaymentSuccessCoins
                                             "
                                             :closeModal="closeModal"
                                             :gtagSelected="gtagSelected"
                                             :paymentMethodProps="paymentMethod"
                                        />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          :class="isPaymentCheckout ? '' : '!hidden z-0'"
          class="fixed inset-0 z-60"
          style="background: rgba(0, 0, 0, 0.75)"
     ></div>
     <paymentSuccess :successCoins="paymentSuccessCoins" />
     <paymentFailed />
     <loading-overlay
          :isLoading="loadingCheckout || (loading && isPaymentCheckout)"
     />
</template>

<script>
import {
     onMounted,
     onUnmounted,
     // onActivated,
     ref,
     reactive,
     computed,
     watch
} from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";
import OnlineBanking from "./component/online-banking.vue";
import CreditCard from "./component/credit-card.vue";
import ApplePay from "./component/apple-pay.vue";
import { useFormatter } from "composables/use-formatter.js";
import { authStore } from "store/auth.js";
import _ from "underscore";
import paymentSuccess from "components/modals/paymentSuccess.vue";
import paymentFailed from "components/modals/paymentFailed.vue";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { useBank } from "composables/use-bank.js";
import { cardStore } from "store/cards.js";
import { coinsStore } from "store/coins";
import device from "middleware/device.js";

export default {
     props: [
          "wp_response",
          "payframeClientLoaded",
          "confirmationFunc",
          "loadingCheckout",
          "setLoadingCheckout",
          "initWorldPay",
          "setConfirmationFunc",
          "loading"
     ],
     components: {
          OnlineBanking,
          CreditCard,
          ApplePay,
          paymentSuccess,
          paymentFailed,
          LoadingOverlay
     },
     setup(props) {
          const { isActionAccessible } = useBank();
          const { money } = useFormatter();
          const { isAndroid } = device();

          const useUtilitiesStore = utilitiesStore();
          const useAuthStore = authStore();
          const useCardStore = cardStore();
          const useCoinsStore = coinsStore();

          const isPaymentCheckout = ref(false);

          const isAddNewCard = ref(false);

          const paymentMethod = ref(0);

          const coin = ref(null);
          const gtagSelected = ref(null);

          const paymentSuccessCoins = reactive({
               gold_coins: 0,
               silver_coins: 0
          });

          const closeModal = () => {
               props.initWorldPay();
               setActiveTab(bankStatus.value);
               isAddNewCard.value = false;
               isPaymentCheckout.value = false;
               useUtilitiesStore.enableScroll();
               props.setConfirmationFunc(false);
          };

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const bankStatus = computed(() => {
               return useCardStore.bankStatus;
          });

          async function setPaymentSuccessCoins(res) {
               paymentSuccessCoins.gold_coins = money(res.gold_coins);
               paymentSuccessCoins.silver_coins = money(res.silver_coins);
          }

          // const initializeApplePay = async () => {
          //      var js,
          //           appsdk_id = "apple-pay-sdk",
          //           d = document;
          //      if (d.getElementById(appsdk_id)) {
          //           // checkAPButton();
          //           return;
          //      }
          //      js = d.createElement("script");
          //      js.id = appsdk_id;
          //      js.async = true;
          //      js.setAttribute("crossorigin", "");
          //      js.src =
          //           "https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js?v=" +
          //           Date.now();
          //      d.head.appendChild(js);

          //      // checkAPButton();
          // };

          // onActivated(() => {
          //      initializeApplePay();
          // });

          const setActiveTab = (newVal) => {
               const filter = _.filter(newVal, function (params) {
                    return [
                         "dp_bank_button",
                         "dp_card_button",
                         "applepay_button"
                    ].includes(params["slug"]);
               });

               if (filter?.length != 0) {
                    const bank = _.filter(newVal, function (params) {
                         return params["slug"] == "dp_bank_button";
                    });

                    const card = _.filter(newVal, function (params) {
                         return params["slug"] == "dp_card_button";
                    });

                    const apple = _.filter(newVal, function (params) {
                         return params["slug"] == "applepay_button";
                    });

                    if (bank[0].note == 1) {
                         paymentMethod.value = 0;
                         return;
                    } else if (card[0].note == 1) {
                         paymentMethod.value = 1;
                         return;
                    } else if (apple[0].note == 1) {
                         paymentMethod.value = 2;
                         return;
                    }
                    return;
               }

               paymentMethod.value = 0;
          };

          const setAddNewCard = (data) => {
               isAddNewCard.value = data;
          };

          onMounted(async () => {
               // initializeApplePay();
               await useCoinsStore.getLimitReach();

               eventBus.on("open:payment_checkout", (data) => {
                    useUtilitiesStore.disableScroll();
                    coin.value = data.coin;
                    gtagSelected.value = data.gtagSelected;
                    isPaymentCheckout.value = true;
               });

               eventBus.on("close:payment_checkout", (data) => {
                    useUtilitiesStore.enableScroll();
                    coin.value = data;
                    gtagSelected.value = data;
                    isPaymentCheckout.value = false;
               });
          });

          onUnmounted(() => {
               eventBus.off("open:payment_checkout");
               eventBus.off("close:payment_checkout");
          });

          watch(bankStatus, (newVal) => {
               if (newVal) {
                    setActiveTab(newVal);
               }
          });

          return {
               isPaymentCheckout,
               closeModal,
               baseUrl,
               paymentMethod,
               coin,
               money,
               setPaymentSuccessCoins,
               isActionAccessible,
               isUser,
               paymentSuccessCoins,
               gtagSelected,
               isAddNewCard,
               setAddNewCard,
               isAndroid
          };
     }
};
</script>
